import { domain, clientId, audience } from '../../auth-config.json';

export const environment = {
  production: false,
  name: 'dev',
  
  apiUrl: "https://as-marsapi-testing-01-or.azurewebsites.net",
  localApiUrl: "https://app.jjins.com/JJAzureWebApi_test",

  // apiUrls: {
  //   local: "https://localhost:44336",
  //   dev: "https://apiv1dev.mga-portal.com",
  //   test: "https://apiv1testing.mga-portal.com",
  //   staging: "https://apiv1staging.mga-portal.com",
  //   production: "https://apiv1.mga-portal.com"
  // },
  auth: {
    domain,
    clientId,
    redirectUri: window.location.origin,
    audience
  },
  animationSettings: {
    effect: "Zoom",
    duration: 400,
    delay: 0,
  },
  leftTopPosition: { X: "left", Y: "top" },
  centerCenterPosition: { X: "center", Y: "center" },
  centerTopPosition: { X: "center", Y: "top" },
  position: { X: "center", Y: "top" },
  filterSettings: { type: "Excel" },
};
