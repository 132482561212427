import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AdministrationService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/administration`;
  }

  public getAdUsers() {
    return this.http.get(`${this.apiUrl}/GetAdUsers`);
  }

  public getGroupSetup(json) {
    return this.http.post(`${this.apiUrl}/GetGroupSetup`, json);
  }
}