import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpClientJsonpModule } from "@angular/common/http"

@Injectable()
export class DocumentManagementService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/documentmanagement`;
  }

  public getUserSettings(userId) {
    return this.http.post(`${this.apiUrl}/GetUserSettings`, {
      userId: userId,
    });
  }

  public getUserLockedTasks() {
    return this.http.post(`${this.apiUrl}/GetUserLockedTasks`, {});
  }

  public getUserTasks() {
    return this.http.post(`${this.apiUrl}/GetUserTasks`, {});
  }

  public saveUserSettings(body) {
    return this.http.post(`${this.apiUrl}/SaveUserSettings`, body);
  }

  public saveGeneralSettings(body) {
    return this.http.post(`${this.apiUrl}/SaveGeneralSettings`, body);
  }

  public pageRotated(body) {
    return this.http.post(`${this.apiUrl}/PageRotated`, body);
  }

  public rotatePage(json) {
    return this.http.post(`${this.apiUrl}/RotatePage`, json);
  }

  public getQuickSearchItems(fileTypeId, searchText, offset) {
    return this.http.post(`${this.apiUrl}/GetQuickSearchItems`, {
      fileTypeId: fileTypeId,
      searchText: searchText,
      offset: offset
    });
  }

  public getAdvancedSearchItems(model) {
    return this.http.post(`${this.apiUrl}/GetAdvancedSearchItems`, model);
  }

  public getFileConnectData(model)
  {
    return this.http.post(`${this.apiUrl}/GetFileConnectData`, model);
  }

  public getAgencyAdvancedSearchItems(model) {
    return this.http.post(`${this.apiUrl}/GetAgencyAdvancedSearchItems`, model);
  }

  public getDocumentTypes() {
    return this.http.post(`${this.apiUrl}/GetDocumentTypes`,{});
  }
  
  public getFolderTypes() {
    return this.http.post(`${this.apiUrl}/GetFolderTypes`,{});
  }
  public changeFolderTypeName(body){
    return this.http.post(`${this.apiUrl}/ChangeFolderTypeName`, body);
  }
  public deleteSubFolder(body){
    return this.http.post(`${this.apiUrl}/DeleteSubFolder`, body);
  }
  public getFileInformation(fileId, fileTypeId) {
    return this.http.post(`${this.apiUrl}/GetFileInformation`, {
      fileId: fileId,
      fileTypeId: fileTypeId,
    });
  }
  
  public getFileExlorerData(fileId, fileTypeId) {
    return this.http.post(`${this.apiUrl}/GetFileExlorerData`, {
      fileId: fileId,
      fileTypeId: fileTypeId,
    });
  }

  public getWorkflowModeSelectionData() {
    return this.http.post(`${this.apiUrl}/GetWorkflowModeSelectionData`, {});
  }
  public getScanBatchDocuments(model) {
    return this.http.post<[]>(`${this.apiUrl}/GetScanBatchDocuments`, model);
  }

  public getWorkflowModeTasks(model) {
    return this.http.post(`${this.apiUrl}/GetWorkflowModeTasks`, model);
  }

  public completeWorkflowTask(model) {
    return this.http.post(`${this.apiUrl}/CompleteWorkflowTask`, model);
  }

  public createWorkflowTask(model) {
    return this.http.post(`${this.apiUrl}/CreateWorkflowTask`, model);
  }

  public lockTask(model) {
    return this.http.post(`${this.apiUrl}/LockTask`, model);
  }

  public unlockTask(model) {
    return this.http.post(`${this.apiUrl}/UnlockTask`, model);
  }

  public getDocumentUrl(formData) {
    return this.http.post(`${this.apiUrl}/GetDocumentUrl`, formData);
  }

  public GetPdf(formData) {
    return this.http.post(`${this.apiUrl}/GetPdf`, formData, {
      responseType: "blob" as "json",
    });
  }
  
  public GetPdfByDocumentId(formData) {
    return this.http.post(`${this.apiUrl}/GetPdfByDocumentId`, formData, {
      responseType: "blob" as "json",
    });
  }

  public dataCorrectionMoveDocument(json) {
    return this.http.post(`${this.apiUrl}/DataCorrectionMoveDocument`, json);
  }
  

  public copyDocument(formData) {
    return this.http.post(`${this.apiUrl}/CopyDocument`, formData);
  }

  public importDocument(formData) {
    return this.http.post(`${this.apiUrl}/ImportDocument`, formData);
  }

  public canConverttoPDF(formData) {
    return this.http.post(`${this.apiUrl}/CanConverttoPDF`, formData);
  }
  

  public updateDocumentType(model) {
    return this.http.post(`${this.apiUrl}/UpdateDocumentType`, model);
  }

  public moveDocuments(model) {
    return this.http.post(`${this.apiUrl}/MoveDocuments`, model);
  }

  public moveDocumentsToFile(model) {
    return this.http.post(`${this.apiUrl}/MoveDocumentsToFile`, model);
  }

  public deleteDocuments(model) {
    return this.http.post(`${this.apiUrl}/DeleteDocuments`, model);
  }

  public getDocumentThumbnails(document) {
    return this.http.post(`${this.apiUrl}/GetDocumentThumbnails`, document);
  }

  public getUrlThumbnails(document) {
    return this.http.post(`${this.apiUrl}/GetUrlThumbnails`, document);
  }

  public downloadDocument(json) {
    return this.http.post(`${this.apiUrl}/DownloadDocument`, json);
  }

  public downloadDocumentWithAnnotations(json) {
    return this.http.post(
      `${this.apiUrl}/DownloadDocumentWithAnnotations`,
      json
    );
  }

  public getWorkflowSelections(json) {
    return this.http.post(`${this.apiUrl}/GetWorkflowSelections`, json);
  }

  public getFileHistory(json) {
    return this.http.post(`${this.apiUrl}/GetFileHistory`, json);
  }

  public getRelatedFilesHistory(json) {
    return this.http.post(`${this.apiUrl}/GetRelatedFilesHistory`, json);
  }

  public getMergedFilesHistory(json) {
    return this.http.post(`${this.apiUrl}/GetMergedFilesHistory`, json);
  }

  public getMarkerTypes() {
    return this.http.get(`${this.apiUrl}/GetMarkerTypes`);
  }

  public savePageDescription(model) {
    return this.http.post(`${this.apiUrl}/SavePageDescription`, model);
  }

  public saveDocumentDescription(model) {
    return this.http.post(`${this.apiUrl}/SaveDocumentDescription`, model);
  }

  public saveFileMarkers(model) {
    return this.http.post(`${this.apiUrl}/SaveFileMarkers`, model);
  }

  public savePageMarkers(model) {
    return this.http.post(`${this.apiUrl}/SavePageMarkers`, model);
  }

  public getFileMarkers(model) {
    return this.http.post(`${this.apiUrl}/GetFileMarkers`, model);
  }

  public getPageMarkers(model) {
    return this.http.post(`${this.apiUrl}/GetPageMarkers`, model);
  }

  public getAdvancedSearchDropdownData() {
    return this.http.post(`${this.apiUrl}/GetAdvancedSearchDropdownData`, {});
  }

  public getMoveDocumentDropdownData() {
    return this.http.get(`${this.apiUrl}/GetMoveDocumentDropdownData`);
  }

  public getFileDetailsDropdownData() {
    return this.http.post(`${this.apiUrl}/GetFileDetailsDropdownData`, {});
  }

  public getFileClearanceDropdownData() {
    return this.http.post(`${this.apiUrl}/GetFileClearanceDropdownData`, {});
  }

  public getAgencyAdvancedSearchDropdownData() {
    return this.http.get(`${this.apiUrl}/GetAgencyAdvancedSearchDropdownData`);
  }

  public saveFileDetails(model) {
    return this.http.post(`${this.apiUrl}/SaveFileDetails`, model);
  }

  public saveFileDetailsMerge(model) {
    return this.http.post(`${this.apiUrl}/SaveFileDetailsMerge`, model);
  }

  public getNoteTypes() {
    return this.http.get(`${this.apiUrl}/GetNoteTypes`);
  }

  public saveFileNote(json) {
    return this.http.post(`${this.apiUrl}/SaveFileNote`, json);
  }

  public fileClearance(json) {
    return this.http.post(`${this.apiUrl}/FileClearance`, json);
  }

  public modifyDocuments(json) {
    return this.http.post(`${this.apiUrl}/ModifyDocuments`, json);
  }

  public updateDocumentDetails(json) {
    return this.http.post(`${this.apiUrl}/UpdateDocumentDetails`, json);
  }

  public downloadFileNotes(json) {
    return this.http.post(`${this.apiUrl}/DownloadFileNotes`, json);
  }

  public createClaimsFile(json) {
    return this.http.post(`${this.apiUrl}/CreateClaimsFile`, json);
  }

  public getFileClearanceItems(json) {
    return this.http.post(`${this.apiUrl}/GetFileClearanceItems`, json);
  }

  public getDefaultEmailSubject(json) {
    return this.http.post(`${this.apiUrl}/GetDefaultEmailSubject`, json);
  }

  public mergeFiles(json) {
    return this.http.post(`${this.apiUrl}/MergeFiles`, json);
  }

  public cancelMergedFiles(json) {
    return this.http.post(`${this.apiUrl}/CancelMergedFiles`, json);
  }

  public saveNamedInsured(json) {
    return this.http.post(`${this.apiUrl}/SaveNamedInsured`, json);
  }

  public getNamedInsuredDropdownData() {
    return this.http.post(`${this.apiUrl}/GetNamedInsuredDropdownData`, {});
  }

  public getWorkflowUsers(json) {
    return this.http.post(`${this.apiUrl}/GetWorkflowUsers`, json);
  }

  public getZipCodeDetails(zipcode) {
    return this.http.post(`${this.apiUrl}/GetZipCodeDetails`, {
      zipCode: zipcode,
    });
  }

  public createEmail(json) {
    return this.http.post(`${this.apiUrl}/CreateEmail`, json);
  }

  public getEmailTemplatesList() {
    return this.http.post(`${this.apiUrl}/GetEmailTemplatesList`, {});
  }

  public getEmailContacts(emailAddress) {
    return this.http.post(`${this.apiUrl}/GetEmailContacts`, {
      emailAddress: emailAddress,
    });
  }

  public deleteNote(json) {
    return this.http.post(`${this.apiUrl}/DeleteNote`, json);
  }

  public getCreateTaskDropdownData() {
    return this.http.post(`${this.apiUrl}/GetCreateTaskDropdownData`, {});
  }

  public searchAgencies(json) {
    return this.http.post(`${this.apiUrl}/SearchAgencies`, json);
  }

  public getDocumentDetailsDropdownData(json) {
    return this.http.post(`${this.apiUrl}/GetDocumentDetailsDropdownData`, json);
  }

  public saveTermGroup(json) {
    return this.http.post(`${this.apiUrl}/SaveTermGroup`, json);
  }

  public terminateTask(json) {
    return this.http.post(`${this.apiUrl}/TerminateTask`, json);
  }

  public terminateWorkflowTasks(json) {
    return this.http.post(`${this.apiUrl}/TerminateWorkflowTasks`, json);
  }

  public getTermGroups() {
    return this.http.get(`${this.apiUrl}/GetTermGroups`);
  }

  public saveAnnotations(json) {
    return this.http.post(`${this.apiUrl}/SaveAnnotations`, json);
  }

  public getDocumentAnnotations(json) {
    return this.http.post(`${this.apiUrl}/GetDocumentAnnotations`, json);
  }

  public deleteAnnotation(json) {
    return this.http.post(`${this.apiUrl}/DeleteAnnotation`, json);
  }

  public getDocumentAnnotationHistory(json) {
    return this.http.post(`${this.apiUrl}/GetDocumentAnnotationHistory`, json);
  }

  public getDocumentVersionAnnotations(json) {
    return this.http.post(`${this.apiUrl}/GetDocumentVersionAnnotations`, json);
  }

  public saveDocumentVersionAnnotations(json) {
    return this.http.post(`${this.apiUrl}/SaveDocumentVersionAnnotations`, json);
  }

  public createCustomStamp(json) {
    return this.http.post(`${this.apiUrl}/CreateCustomStamp`, json);
  }

  public getCustomStamps() {
    return this.http.post(`${this.apiUrl}/GetCustomStamps`, {});
  }
  
  public getCustomStampsDropdownData() {
    return this.http.post(`${this.apiUrl}/GetCustomStampsDropdownData`, {});
  }

  public CreateBlob(d: any, mimeType) {
    let reader = new FileReader();

    var binary = atob((<string>d).replace(/\s/g, ""));
    var len = binary.length;

    // create ArrayBuffer with binary length
    var buffer = new ArrayBuffer(len);

    // create 8-bit Array
    var view = new Uint8Array(buffer);

    // save unicode of binary data into 8-bit Array
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    var blob = new Blob([view], { type: mimeType });
    return blob;
  }

  public checkIfPolicyNumberExists(json) {
    return this.http.post<any[]>(`${this.apiUrl}/CheckIfPolicyNumberExists`, json);
  }

  public getPolicyTerms(json) {
    return this.http.post(`${this.apiUrl}/GetPolicyTerms`, json);
  }

  public updateTask(json) {
    return this.http.post(`${this.apiUrl}/UpdateTask`, json);
  }

  public updateWorkflowTasks(json) {
    return this.http.post(`${this.apiUrl}/UpdateWorkflowTasks`, json);
  }

  public copyPastePages(json) {
    return this.http.post(`${this.apiUrl}/CopyPastePages`, json);
  }

  public deletePages(json) {
    return this.http.post(`${this.apiUrl}/DeletePages`, json);
  }

  public saveTaskNote(json) {
    return this.http.post(`${this.apiUrl}/SaveTaskNote`, json);
  }

  public getFileIndexingData(json) {
    return this.http.post(`${this.apiUrl}/GetFileIndexingData`, json);
  }

  public saveFileIndexingData(json) {
    return this.http.post(`${this.apiUrl}/SaveFileIndexingData`, json);
  }
  
  public getMarkedPages(json) {
    return this.http.post(`${this.apiUrl}/GetMarkedPages`, json);
  }
  
  public getvpFileById(id) {
    return this.http.post(`${this.apiUrl}/GetTempVPFile`, {vpFileId : id });
  }

  public createPdfPages(json) {
    return this.http.post(`${this.apiUrl}/CreatePdfPages`, json);
  }

  public getEmailTemplate(json) {
    return this.http.post(`${this.apiUrl}/GetEmailTemplate`, json);
  }
  
  public getEmailSignature(json) {
    return this.http.post(`${this.apiUrl}/GetEmailSignature`, json);
  }

  public getImagingBarcode(filePolicyId) {
    return this.http.get(`${this.apiUrl}/GetImagingBarcode?filePolicyId=${filePolicyId}`);
  }

  public chekIfPolicyHasDocuments (json)
  {
    return this.http.post(`${this.apiUrl}/ChekIfPolicyHasDocuments`, json);
  }

  public getDataCorrection ()
  {
    return this.http.post(`${this.apiUrl}/GetDataCorrection`, null);
  }
}
