import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  @Output() cabinetChanged = new EventEmitter();
  @Output() drawerChanged = new EventEmitter();

  constructor(private http: HttpClient) { }

  // //Entities
  // public getCabinets() {
  //   return this.http.get(`documentmanagement/Base/GetCabinets`);
  // }

  // public getCabinetsWithDrawers() {
  //   return this.http.get(`documentmanagement/Base/GetCabinetsWithDrawers`);
  // }

  // public saveCabinet(model) {
  //   return this.http.post(`documentmanagement/Base/SaveCabinet`, model);
  // }

  // public deleteCabinet(model) {
  //   return this.http.post(`documentmanagement/Base/DeleteCabinet`, model);
  // }

  // //FileTypes
  // public getDrawers(cabinetId) {
  //   return this.http.get(`documentmanagement/Base/GetDrawers?cabinetId=${cabinetId}`);
  // }

  // public saveDrawer(model) {
  //   return this.http.post(`documentmanagement/Base/SaveDrawer`, model);
  // }

  // public deleteDrawer(model) {
  //   return this.http.post(`documentmanagement/Base/DeleteDrawer`, model);
  // }

  // //Folders
  // public getFolders(drawerId) {
  //   return this.http.get(`documentmanagement/Base/GetFolders?drawerId=${drawerId}`);
  // }

  // public saveFolder(model) {
  //   return this.http.post(`documentmanagement/Base/SaveFolder`, model);
  // }

  // public deleteFolder(model) {
  //   return this.http.post(`documentmanagement/Base/DeleteFolder`, model);
  // }

  // //Document Types
  // public getDocumentTypes(drawerId) {
  //   return this.http.get(`documentmanagement/Base/GetDocumentTypes?drawerId=${drawerId}`);
  // }

  // public saveDocumentType(model) {
  //   return this.http.post(`documentmanagement/Base/SaveDocumentType`, model);
  // }

  // public deleteDocumentType(model) {
  //   return this.http.post(`documentmanagement/Base/DeleteDocumentType`, model);
  // }

  // //Features
  // public getFeatures() {
  //   return this.http.get(`documentmanagement/Base/GetFeatures`);
  // }

  // //Users
  // public getAdUsers() {
  //   return this.http.get(`documentmanagement/Base/GetAdUsers`);
  // }

  // //Workflows
  // public getWorkflows(drawerId) {
  //   return this.http.get(`documentmanagement/Base/GetWorkflows?drawerId=${drawerId}`);
  // }

  // public saveWorkflow(model) {
  //   return this.http.post(`documentmanagement/Base/SaveWorkflow`, model);
  // }

  // public getStateList() {
  //   return this.http.get(`Admin/GetStateList`);
  // }

  // public getCompanyList(division) {
  //   return this.http.get(`Admin/GetCompaniesList?division=${division}`);
  // }

  // public getFormsList(formNumber) {
  //   return this.http.get(`Admin/GetFormsList?formNumber=${formNumber}`);
  // }

  // public savePolicyFormRules(model) {
  //   return this.http.post(`Admin/SavePolicyFormRules`, model);
  // }

  // public getPolicyFormRules() {
  //   return this.http.get(`Admin/GetPolicyFormRules`);
  // }

  // public deletePolicyFormRules(model) {
  //   return this.http.post(`Admin/DeletePolicyFormRules`, model);
  // }

  // public getPolicyFormRuleCriteria() {
  //   return this.http.get(`Admin/GetPolicyFormRuleCriteria`);
  // }

  // public getPolicyFormRuleComparison(model) {
  //   return this.http.post(`Admin/GetPolicyFormRuleComparison?companyId`, model);
  // }
}
