<div>
    <div class="close">
        <button class="close" mat-button (click)="close()">X</button>
    </div>
    <label class="online-renewal-info">{{ wording }}</label>
    <div class="mt-2 online-renewal-container">            
        <div *ngFor="let policy of policies" class="col-11">
            <table class="or-table ml-4 mb-2 mt-4">
                <tr class="or-header-row">
                    <th class="or-header-cell">Policy Number</th >
                    <th class="or-header-cell">Insured Name</th>
                    <th class="or-header-cell">Expiration Date</th>
                    <th class="or-header-cell">Total Premium</th>
                </tr>
                <tr class="or-row">
                    <td class="or-cell">{{ policy.policyNumber }}</td>
                    <td class="or-cell">{{ policy.insuredName }}</td>
                    <td class="or-cell">{{ policy.expirationDate | date: 'MM/dd/yyyy' }}</td>
                    <td class="or-cell">{{ policy.totalPremium }}</td>
                </tr>
            </table>
        
            <div class="col-10 mb-2 mt-4 online-renewal-label-placement">
                <label class="online-renewal-label">Why will this policy not be renewed?</label>
        
                <div class="form-field-parent">
                    <mat-select class="form-control online-renewal-dropdown" placeholder="ENTER REASON" [(ngModel)]="policy.reason"
                        [ngClass]="{'red-border-class': reasonRequired && (!policy.reason || policy.reason == '')}">
                        <mat-option *ngFor="let opt of reasonOptions" [value]="opt.optionName" class="online-renewal-dropdown">
                            <div class="row">
                                <div class="col-12">
                                    {{ opt.optionName }}
                                </div>
                            </div> 
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-10 mb-2 mt-4 online-renewal-label-placement" *ngIf="policy.reason && policy.reason == 'OTHER'">        
                <div class="form-field-parent">
                    <input type="text" class="form-control online-renewal-text" [(ngModel)]="policy.otherReasons" placeholder="ENTER OTHER REASON"/>
                </div> 
            </div>
            <div class="col-10 mb-2 mt-4 online-renewal-label-placement">
                <label class="online-renewal-label">Additional Comments</label>
        
                <div class="form-field-parent">
                    <input type="text" class="form-control online-renewal-text" [(ngModel)]="policy.comments" placeholder="ENTER COMMENTS" required="commentsRequired"
                        [ngClass]="{'red-border-class': commentsRequired && (!policy.comments || policy.comments == '')}"/>
                </div> 
            </div>
        </div>
    </div>    
    <div class="col-12 mt-4 online-renewal-center">
        <button class="btn btn-outline-secondary rounded-pill me-2" (click)="close()">Cancel</button>
        <button class="btn btn-success rounded-pill" (click)="confirm()">Continue</button>
    </div>
</div>
