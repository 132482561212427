import { Injectable,Inject } from "@angular/core";
import {HttpClient } from '@angular/common/http';

@Injectable()
export class ViewerService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/viewer`;
  }

  public setViewerDocument(json) {
    return this.http.post(`${this.apiUrl}/SetViewerDocument`, json);
  }

  public setViewerDocumentBase64(json) {
    return this.http.post(`${this.apiUrl}/SetViewerDocumentBase64`, json);
  }

  public addCustomAnnotation(json) {
    return this.http.post(`${this.apiUrl}/SaveCustomStamp`, json);
  }

  public removeCustomAnnotation(name:string) {
    return this.http.delete(`${this.apiUrl}/CustomAnnotation/${name}`);
  }

  public uploadImage(image: File)/*: Observable<Response>*/ {
    const formData = new FormData();

      formData.append('image', image);

    return this.http.post(`${this.apiUrl}/UploadAnnotation`, formData/*, { headers }*/);
  }

  public getStampAnnotations() {
    return this.http.post(`${this.apiUrl}/GetStampAnnotations`, {});
  }

  public deleteCustomStamp(json) {
    return this.http.post(`${this.apiUrl}/DeleteCustomStamp`, json);
  }

  public getStampImageData(json) {
    return this.http.post(`${this.apiUrl}/GetStampImageData`, json); 
  }
  
  public getViewerData(json) {
    return this.http.post(`${this.apiUrl}/GetViewerData`, json);
  }

  public getAnnotationHistory(json) {
    return this.http.post(`${this.apiUrl}/GetAnnotationHistory`, json);
  }

  public saveAnnotations(json) {
    return this.http.post(`${this.apiUrl}/SaveAnnotations`, json);
  }

  public rotatePage(json) {
    return this.http.post(`${this.apiUrl}/RotatePage`, json);
  }
  
  public getCustomStampDropdownData() {
    return this.http.post(`${this.apiUrl}/GetCustomStampDropdownData`, {});
  }
  
  public getCustomStamps() {
    return this.http.post(`${this.apiUrl}/GetCustomStamps`, {});
  }

  public getCustomAnnotations() {
    return this.http.post(`${this.apiUrl}/GetCustomAnnotations`, {});
  }

  public updateCustomAnnotationImageUrl(json) {
    return this.http.post(`${this.apiUrl}/UpdateCustomAnnotationImageUrl`, json);
  }
}
