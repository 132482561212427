import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../user-service';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';

@Component({
  selector: 'app-toggle-email-notifications-dialog',
  templateUrl: './toggle-email-notifications-dialog.component.html',
  styleUrls: ['./toggle-email-notifications-dialog.component.scss']
})
export class ToggleEmailNotificationsDialogComponent implements OnInit {
  noticeType: string = "enable";
  applyAllPolicies: string;
  isAdministrator: boolean = false;
  applyAllPoliciesRequired: boolean = false;
  validationRules;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ToggleEmailNotificationsDialogComponent>, private onlineRenewals: OnlineRenewalsService, public user: UserService){ 
    this.noticeType = data.noticeType;
    if (user.profile.userType == "employee" || user.profile.accessType == "administrator" || user.profile.accessType == "admin") {
      this.isAdministrator = true;
    }
    else {
      this.isAdministrator = false;
    }
  }

  ngOnInit(): void {
    this.getValidationRules();
  }

  getValidationRules(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetValidationRules', json: { pageName: this.noticeType }}).subscribe(res => {
      this.validationRules = res;
      if (this.validationRules.filter(p => p.controlName == 'applyAllPolicies').length > 0){
        this.applyAllPoliciesRequired = this.validationRules.filter(p => p.controlName == 'applyAllPolicies')[0].isRequired;
      }
      sub.unsubscribe();
    });
  }

  toggleForAll(enable){
    this.applyAllPolicies = enable ? "true" : "false";
  }

  close(){
    this.dialogRef.close();
  }

  confirm(){
    var data = {}
    data["changeType"] = this.noticeType;
    data["applyAllPolicies"] = this.applyAllPolicies;
    data["userId"] = this.user.profile.userId;
    this.dialogRef.close(data);
  }
}
