<div>
    <div class="close">
        <button class="close" mat-button (click)="close()">X</button>
    </div>
    <label class="online-renewal-info">{{ wording }}</label>

    <div class="mt-2 online-renewal-container">  
        <label class="online-renewal-label">{{ title }}</label>

        <!-- POLICY INFO -->
        <div *ngFor="let p of policy" class="col-11">
            <table class="or-table ml-4 mb-2 mt-4">
                <tr class="or-header-row">
                    <th class="or-header-cell">Policy Number</th >
                    <th class="or-header-cell">Insured Name</th>
                    <th class="or-header-cell">Expiration Date</th>
                    <th class="or-header-cell">Total Premium</th>
                </tr>
                <tr class="or-row">
                    <td class="or-cell">{{ p.policyNumber }}</td>
                    <td class="or-cell">{{ p.insuredName }}</td>
                    <td class="or-cell">{{ p.expirationDate | date: 'MM/dd/yyyy' }}</td>
                    <td class="or-cell">{{ p.totalPremium }}</td>
                </tr>
            </table>
        </div>
        
        <!-- FILE UPLOAD -->
        <div class="col-md-12">
            <ngx-dropzone class="dropzone" (change)="onSelect($event)" [disableClick]="disableClick"> 
                <ngx-dropzone-label *ngIf="uploadedForms.length < 1">Drag/Drop Files or Click to Choose Files</ngx-dropzone-label>    
                <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)" *ngIf="uploadedForms.length > 0">
                    <div class="example-box" *ngFor="let f of uploadedForms" cdkDrag>
                        {{ f.name }}
                        <div class="row">
                            <div class="col-1">
                                <i class="fa fa-search delete-preview" aria-hidden="true" (click)="previewForm(f)"></i>
                            </div>
                            <div class="col-1">
                                <i class="fa fa-trash delete-preview" aria-hidden="true" (click)="removeForm(f)"></i>
                            </div>
                        </div>
                    </div>                    
                </div>
            </ngx-dropzone>
        </div>

        <!-- <label class="online-renewal-label">To:</label>
        <div class="form-field-parent">
            <input type="text" class="form-control" [(ngModel)]="emailTos" placeholder="Enter email address" disabled="true"/>
        </div>  -->

        <label class="online-renewal-label">CC:</label>
        <div class="form-field-parent">
            <input type="text" class="form-control" [(ngModel)]="CCList" placeholder="Enter emails, separate with ';'" required="ccListRequired"
                [ngClass]="{'red-border-class': ccListRequired && (!CCList || CCList == '')}"/>
        </div> 

        <!-- <label class="online-renewal-label">Subject:</label>
        <div class="form-field-parent">
            <input type="text" class="form-control" [(ngModel)]="subject" placeholder="Enter subject"/>
        </div>  -->

        <label class="online-renewal-label" *ngIf="showPreloadedOptions">Preloaded Message Options:</label>
        <div class="form-field-parent" *ngIf="showPreloadedOptions" [formGroup]="preloadOptions">
            <mat-select class="form-control online-renewal-dropdown" placeholder="Select Option(s)" formControlName="options" multiple>
                <mat-option *ngFor="let opt of preloadedOptions" [value]="opt.optionName" class="online-renewal-dropdown-options"> 
                    {{ opt.optionName }}
                </mat-option>
            </mat-select>
        </div>

        <label class="online-renewal-label">Message:</label>
        <div class="form-field-parent-message">
            <ejs-richtexteditor id='defaultRTE' [toolbarSettings]="tools" [quickToolbarSettings]="quickTools" [(ngModel)]="body" [placeholder]="placeholder" 
                [enableTabKey]="true" [ngClass]="{'red-border-class': bodyRequired && (!body || body == '')}">
                <ng-template #valueTemplate class="form-control">
                    <p></p>
                </ng-template>
            </ejs-richtexteditor>
        </div> 
    </div>
    <div class="col-12 mt-4 online-renewal-center">
        <button class="btn btn-outline-secondary rounded-pill me-2" (click)="close()">Cancel</button>
        <button class="btn btn-success rounded-pill" (click)="confirm()" 
            [disabled]="(uploadFormsRequired && uploadedForms.length < 1) || (ccListRequired && (!CCList || CCList == '')) || (bodyRequired && body == '')">Continue</button>
    </div>
</div>

<ngx-spinner name="loading" [fullScreen]="false" type="ball-circus" size="default">
</ngx-spinner>
