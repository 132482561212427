import { Injectable, Output, EventEmitter, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AgencyManagementService {
  apiUrl;
  @Output() agencyCodeChangedEvent = new EventEmitter<{}>();
  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/agencymanagement`;
  }

  public getAgencyContacts(agencyId) {
    return this.http.post(`${this.apiUrl}/GetAgencyContacts`, {
      agencyId: agencyId
    });
  }

  public GetAgencies(viewType: string) {
    const url = `${this.apiUrl}/GetAgencies?viewType=` + viewType;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(url, { headers: headers })
      .pipe(map((res: Response) => res))
      .pipe(catchError(err => of(err)));
  }

  public saveW9(model: any, agencyCode: number, user: string) {
    const url = `${this.apiUrl}/AgencyManagement/saveW9`;

    const postData: { id: string, value: any }[] = [
      { 'id': 'model', 'value': model },
      { 'id': 'agencyCode', 'value': agencyCode },
      { 'id': 'user', 'value': user }
  ];

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, postData, { headers: headers })
      .pipe(map((res: Response) => res))
      .pipe(catchError(err => of(err)));
  }

  public getUserAccessType(agencyCode, email) {
    let url = 'api/AgencyManagement/GetUserAccessType?agencyCode=' + agencyCode + '&email=' + email;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(url, { headers: headers })
      .pipe(map((res: Response) => res))
      .pipe(catchError(err => of(err)));
  }

  public getEmailPreferences(agencyCode) {
    let url = 'api/AgencyManagement/GetEmailPreferences?agencyCode=' + agencyCode;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(url, { headers: headers })
      .pipe(map((res: Response) => res))
      .pipe(catchError(err => of(err)));
  }

  public updateEmailPreferences(json) {
    let url = 'api/AgencyManagement/UpdateEmailPreferences';

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, json, { headers: headers })
      .pipe(map((res: Response) => res))
      .pipe(catchError(err => of(err)));
  }
}