// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { domain, clientId, audience } from "../../auth-config.json";

export const environment = {
  production: false,
  name: 'dev',
  database: 'dev',

  apiUrl: "https://localhost:44336",
  localApiUrl: 'https://localhost:44318',
  //apiUrl: 'https://app.jjins.com/JJAzureWebApi_test',
  // apiUrls: {
  //   local: "https://localhost:44336",
  //   dev: "https://apiv1dev.mga-portal.com",
  //   test: "https://apiv1testing.mga-portal.com",
  //   staging: "https://apiv1staging.mga-portal.com",
  //   production: "https://apiv1.mga-portal.com"
  // },
  // localApiUrls: {
  //   local: "https://localhost:44318",
  //   dev: "https://app.jjins.com/JJAzureWebApi",
  //   test: "https://app.jjins.com/JJAzureWebApi_test",
  //   staging: "https://app.jjins.com/JJAzureWebApi_staging",
  //   production: "https://app.jjins.com/JJAzureWebApi"
  // },
  auth: {
    domain,
    clientId,
    redirectUri: window.location.origin,
    audience,
  },
  kubeUrl: "https://api.mga-portal.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
