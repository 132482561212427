import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from 'projects/administration/src/app/user-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'online-renewals';
  private subscription = new Subscription();

  constructor(
    private router: Router,
    public auth: AuthService
    , private user: UserService
    ) {
    this.subscription.add(
      this.auth.user$.subscribe((profile) => {
        this.user.init(profile);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  login() {
    this.auth.loginWithRedirect();
  }
}
