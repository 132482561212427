import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../user-service';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';

@Component({
  selector: 'app-update-email-dialog',
  templateUrl: './update-email-dialog.component.html',
  styleUrls: ['./update-email-dialog.component.scss']
})
export class UpdateEmailDialogComponent implements OnInit {

  agentName: string;
  agentEmail: string;
  applyAllPolicies: string;

  validationRules;
  applyAllPoliciesRequired: boolean = false;
  agentNameRequired: boolean = false;
  agentEmailRequired: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UpdateEmailDialogComponent>, private onlineRenewals: OnlineRenewalsService, public user: UserService) { }

  ngOnInit(): void {
    this.getValidationRules();
  }

  getValidationRules(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetValidationRules', json: { pageName: 'updateEmail' }}).subscribe(res => {
      this.validationRules = res;
      if (this.validationRules.filter(p => p.controlName == 'applyAllPolicies').length > 0){
        this.applyAllPoliciesRequired = this.validationRules.filter(p => p.controlName == 'applyAllPolicies')[0].isRequired;
      }
      if (this.validationRules.filter(p => p.controlName == 'agentName').length > 0){
        this.agentNameRequired = this.validationRules.filter(p => p.controlName == 'agentName')[0].isRequired;
      }
      if (this.validationRules.filter(p => p.controlName == 'agentEmail').length > 0){
        this.agentEmailRequired = this.validationRules.filter(p => p.controlName == 'agentEmail')[0].isRequired;
      }
      sub.unsubscribe();
    });
  }

  close(){
    this.dialogRef.close();
  }

  confirm(){
    var data = {};
    data["agentName"] = this.agentName;
    data["agentEmail"] = this.agentEmail;
    data["applyAllPolicies"] = this.applyAllPolicies;
    data["userId"] = this.user.profile.userId;
    
    this.dialogRef.close(data);
  }
}
