import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToolbarService, LinkService, HtmlEditorService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { UserService } from '../../user-service';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';
import { FormViewerDialogComponent } from '../form-viewer-dialog/form-viewer-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  styleUrls: ['./upload-documents-dialog.component.scss'],
  providers: [ToolbarService, LinkService, HtmlEditorService, QuickToolbarService]
})
export class UploadDocumentsDialogComponent implements OnInit {

  subscription: Subscription;
  emailTos: string;
  emailFrom: string;
  CCList: string;
  subject: string;
  body: string = "";
  uploadedForms: any[] = [];
  postMultimedias: any[] = [];
  policy: any;
  title: string;
  emailData: any = {};
  preloadedOptions;
  showPreloadedOptions: boolean = true;
  preloadOptions: FormGroup;
  disableClick: boolean = false;
  wording: string;
  wordingData;
  placeholder: string = 'Enter your message here';

  validationRules;
  uploadFormsRequired: boolean = false;
  ccListRequired: boolean = false;
  bodyRequired: boolean = false;

  @ViewChild('fileInput') fileInput:ElementRef;

  tools: object = {
    items: ['Undo', 'Redo', '|',
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };
  quickTools: object = {
      image: [
          'Replace', 'Align', 'Caption', 'Remove', 'InsertLink', '-', 'Display', 'AltText', 'Dimension']
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UploadDocumentsDialogComponent>, private dialog: MatDialog, public user: UserService, private onlineRenewals: OnlineRenewalsService, private spinner: NgxSpinnerService) 
  { 
    this.subscription = new Subscription();
    this.policy = data.policy
    this.title = data.title;

    this.emailData["policyId"] = this.policy[0].policyId;
    this.emailData["renewalId"] = this.policy[0].renewalId;
    this.emailData["statusId"] = data.statusId;
    this.emailData["attachments"] = [];

    if (this.user.profile && this.user.profile.userType == "employee"){
      this.showPreloadedOptions = true;
    }
    else {
      this.showPreloadedOptions = false;
    }
  }

  ngOnInit(): void {
    this.getDropDownData();
    this.getValidationRules();

    this.preloadOptions = new FormGroup({
      options: new FormControl()
    });

    this.preloadOptions.get('options').valueChanges.subscribe(item => {
      this.body = "";
      item.forEach(i => {
      this.body = this.body + "<p>" + i + "</p>";
      });
    });
  }

  getDropDownData(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetDropDownData', json: { dropDownOption: "preloadComments" }}).subscribe(res => {
      this.preloadedOptions = res;
      sub.unsubscribe();
    });

    var sub2 = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetScreenWording', json: { screenType: this.title }}).subscribe(d => {
      this.wordingData = d;
      this.wording = this.wordingData.wording;
      sub2.unsubscribe();
    });
  }

  getValidationRules(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetValidationRules', json: { pageName: this.title }}).subscribe(res => {
      this.validationRules = res;
      if (this.validationRules.filter(p => p.controlName == 'uploadedForms').length > 0){
        this.uploadFormsRequired = this.validationRules.filter(p => p.controlName == 'uploadedForms')[0].isRequired;
      }
      if (this.validationRules.filter(p => p.controlName == 'ccList').length > 0){
        this.ccListRequired = this.validationRules.filter(p => p.controlName == 'ccList')[0].isRequired;
      }
      if (this.validationRules.filter(p => p.controlName == 'body').length > 0){
        this.bodyRequired = this.validationRules.filter(p => p.controlName == 'body')[0].isRequired;
      }
      sub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
  }

  close(){
    this.dialogRef.close();
  }

  confirm(){
    this.emailData["emailTos"] = this.emailTos;
    this.emailData["CCList"] = this.CCList;
    this.emailData["subject"] = this.subject;
    this.emailData["body"] = this.body;
    this.emailData["emailFrom"] = this.emailFrom;
    this.emailData["attachments"] = this.postMultimedias;
    this.emailData["sendEmail"] = true;
    this.emailData["changeType"] = this.title;
    this.emailData["userId"] = this.user.profile.userId;

    this.dialogRef.close(this.emailData);
  }

  onSelect(event){
    this.uploadedForms.push(...event.addedFiles);
    if (this.uploadedForms && this.uploadedForms[0]) {
      //[NEWOR-122] - clear out postMultimedias before pushing forms to prevent duplicates
      this.postMultimedias = [];
      for (let i = 0; i < this.uploadedForms.length; i++) {
       this.fileToBase64(this.uploadedForms[i])
        .then(result=>{
          this.postMultimedias.push({ name:this.uploadedForms[i].name,content: 
           result});//postMultimedias is a array which holds image name and bas64String
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString());
        reader.onerror = error => reject(error);
    })
  }
  
  readFile(form): Observable<any>{
    let reader = new FileReader();
    return new Observable(obs => {
      reader.readAsDataURL(form.file);
      reader.onload = () => {
        let newForm: any = {
          "userId": this.user.profile.userId,
          "name": form.name,
          "bytes": reader.result,
        }      
        obs.next(newForm);
        obs.complete();
      }   
    });
  }

  removeForm(event){
    this.spinner.show("loading"); 
    this.disableClick = true;

    function removeUploadedForm(event, uploadedForms, postMultimedias) {
      return new Promise((resolve, reject) => {
          setTimeout(() => { 
            let position = uploadedForms.indexOf(event);
            postMultimedias.splice(position, 1);
            uploadedForms.splice(position, 1);
            resolve(true)
          }, 500)
      })
    }
    
    async function enableClick(event, uploadedForms, postMultimedias, disableClick) {
        const result = await removeUploadedForm(event, uploadedForms, postMultimedias)
        if (uploadedForms.length == 0 || result){
          return false;
        }
        return true;
    }; 

    enableClick(event, this.uploadedForms, this.postMultimedias, this.disableClick).then(res => this.disableClick = res).then(res => this.spinner.hide("loading"));
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.uploadedForms, event.previousIndex, event.currentIndex);
  }

  previewForm(event) {
    this.disableClick = true;
    let dialogRef = this.dialog.open(FormViewerDialogComponent, {
      disableClose: false,
      width: '1000px',
      height: '700px',
      data: {
        fileList: this.uploadedForms.filter(f => f.name == event.name)
      }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(a => {
    }));
  }
}

