import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { POPOUT_MODAL_DATA, POPOUT_MODALS, PopoutModalName } from './../models/popout-data';

@Injectable()
export class PopoutService implements OnDestroy {
    styleSheetElement;

    constructor(
        private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef
    ) {
    }

    ngOnDestroy() { }

    openPopoutModal(data, windowSettings) {
        const windowInstance = this.openOnce(
            'assets/modal/popout.html',
            'MODAL_POPOUT',
            data,
            windowSettings
        );

        // Wait for window instance to be created
        setTimeout(() => {
            console.log("removed this FUNCTION!!!")
            //this.createCDKPortal(data, windowInstance);
        }, 1000);
    }

    openOnce(url, target, data, windowSettings) {
        let width = windowSettings?.width ?? 1000;
        let height = windowSettings?.height ?? 700;

        const winRef = window.open('', target, `menubar=no,fullscreen=yes,location=no,toolbar=no,scrollbars=yes,resizable=yes,titleBar=no,width=${width},height=${height}`);
        
        // If the "target" window was just opened, change its url
        if (winRef.location.href === 'about:blank') {
            winRef.location.href = url;
        }
        return winRef;
    }

    createCDKPortal(data, windowInstance) {
        if (windowInstance) {
            // Create a PortalOutlet with the body of the new window document
            const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
            
            // Copy styles from parent window
            document.querySelectorAll('style').forEach(htmlElement => {
                windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
            });

            // Copy stylesheet link from parent window
            this.styleSheetElement = this.getStyleSheetElement();
            windowInstance.document.head.appendChild(this.styleSheetElement);

            this.styleSheetElement.onload = () => {
                // Clear popout modal content
                windowInstance.document.body.innerText = '';

                // Create an injector with modal data
                const injector = this.createInjector(data);

                // Attach the portal
                let componentInstance;
                if (data.modalName === PopoutModalName.composeEmail) {
                    windowInstance.document.title = 'Compose Email';
                    //****REMVOED THIS componentInstance = this.attachComposeEmailContainer(outlet, injector);
                }

                POPOUT_MODALS['windowInstance'] = windowInstance;
                POPOUT_MODALS['outlet'] = outlet;
                POPOUT_MODALS['componentInstance'] = componentInstance;
            };
        }
    }

    isPopoutWindowOpen() {
        return POPOUT_MODALS['windowInstance'] && !POPOUT_MODALS['windowInstance'].closed;
    }

    focusPopoutWindow() {
        POPOUT_MODALS['windowInstance'].focus();
    }

    closePopoutModal() {
        Object.keys(POPOUT_MODALS).forEach(modalName => {
            if (POPOUT_MODALS['windowInstance']) {
                POPOUT_MODALS['windowInstance'].close();
            }
        });
    }

    // attachComposeEmailContainer(outlet, injector) {
    //     const containerPortal = new ComponentPortal(ComposeEmailComponent, null, injector);
    //     const containerRef: ComponentRef<ComposeEmailComponent> = outlet.attach(containerPortal);
    //     return containerRef.instance;
    // }

    createInjector(data): Injector {
        const injectionTokens = new WeakMap();
        injectionTokens.set(POPOUT_MODAL_DATA, data);
        return Injector.create({
            providers: [{ provide: 'POPOUT_MODAL_DATA', useValue: injectionTokens }]
        })
    }

    getStyleSheetElement() {
        const styleSheetElement = document.createElement('link');
        document.querySelectorAll('link').forEach(htmlElement => {
            if (htmlElement.rel === 'stylesheet') {
                const absoluteUrl = new URL(htmlElement.href).href;
                styleSheetElement.rel = 'stylesheet';
                styleSheetElement.href = absoluteUrl;
            }
        });
        return styleSheetElement;
    }
}