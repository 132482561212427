<ul>
    <li>
        <a href="#" routerLink="dashboard">Dashboard</a>
    </li>
    <li>
        <a href="#" routerLink="quotes">Quotes</a>
    </li>
    <li>
        <a href="#">Policies</a>
    </li>
    <li>
        <a href="#">Claims</a>
    </li>
    <li>
        <a href="#">Reports</a>
    </li>
    <li>
        <a href="#">Bulletins</a>
    </li>
    <li>
        <a href="#" routerLink="agencymanagement">Agency Management</a>
    </li>
    <li>
        <a href="#" routerLink="maintenance">Maintenance</a>
    </li>
    <li>
        <!-- <a href="#" routerLink="formsService"
            [queryParams]="{VersionID:147272,tranType:1, userID:275,databaseEnvironment: env.database}">Forms Service
            example</a> -->
    </li>
</ul>