import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OnlinePolicyRenewalsComponent } from './online-policy-renewals/online-policy-renewals.component';

const routes: Routes = [
  {
    path: "",
    component: OnlinePolicyRenewalsComponent,
    canActivate: [AuthGuard], data: { roles: ['employee','agent'] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
