import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { FilterService, GridModule, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { SideNav } from 'projects/framework/src/lib/side-nav/side-nav.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';
import { SharedServicesModule } from 'projects/framework/src/lib/services/shared-services.module';
import { environment } from "../environments/environment";
import { CheckBoxSelection, DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { AgGridModule } from 'ag-grid-angular';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { RichTextEditorModule, ToolbarSettingsModel, QuickToolbarSettingsModel } from "@syncfusion/ej2-angular-richtexteditor";
import { CheckBoxSelectionService, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { HttpConfigInterceptor } from "./http-config-interceptor";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { AuthGuard } from "projects/framework/Auh.Guard";
import { NavbarComponent } from './navbar/navbar.component';
import { DeleteRenewalDialogComponent } from './online-policy-renewals/delete-renewal-dialog/delete-renewal-dialog.component';
import { OnlinePolicyRenewalsComponent } from './online-policy-renewals/online-policy-renewals.component';
import { UpdateEmailDialogComponent } from './online-policy-renewals/update-email-dialog/update-email-dialog.component';
import { ToggleEmailNotificationsDialogComponent } from './online-policy-renewals/toggle-email-notifications-dialog/toggle-email-notifications-dialog.component';
import { WillNotRenewDialogComponent } from './online-policy-renewals/will-not-renew-dialog/will-not-renew-dialog.component';
import { UploadDocumentsDialogComponent } from './online-policy-renewals/upload-documents-dialog/upload-documents-dialog.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';


@NgModule({
  declarations: [
    AppComponent,
    SideNav,
    OnlinePolicyRenewalsComponent,
    WillNotRenewDialogComponent,
    UpdateEmailDialogComponent,
    ToggleEmailNotificationsDialogComponent,
    UploadDocumentsDialogComponent,
    NavbarComponent,
    DeleteRenewalDialogComponent
  ],
  imports: [
    AppRoutingModule,
    DragDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DropDownListAllModule,
    FormsModule,
    GridModule,
    HttpClientModule,
    NgbModule,
    ButtonModule,
    CheckBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SwitchModule,
    SharedServicesModule.forRoot({ ...environment }),
    AgGridModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
    RichTextEditorModule,
    SnotifyModule,
    MultiSelectModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [
          environment.apiUrl + "/*",
          environment.localApiUrl + "/*"
        ],
      },
    })
  ],
  providers: [
    FilterService,
    OnlineRenewalsService,
    SortService,    
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    ResizeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
