import { Injectable, Output, EventEmitter, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CommonService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/common`;
  }

  public getAdminMenuItems() {
    return this.http.get(`${this.apiUrl}/GetAdminMenuItems`);
  }
  
  public getUsers() {
    return this.http.get(`${this.apiUrl}/GetUsers`);
  }

  public getZipCodeDetails(zipcode) {
    return this.http.post(`${this.apiUrl}/GetZipCodeDetails`, {
      zipCode: zipcode
    });
  }

  public getCountries() {
    return this.http.get(`${this.apiUrl}/GetCountries`);
  }

  public getMarsUrl() {
    return this.http.get(`${this.apiUrl}/GetMarsUrl`);
  }

  public sendEmail(json) {
    return this.http.post(`${this.apiUrl}/SendEmailUsingGraphAPI`, json);
  }

  public getOutlookContacts(json) {
    return this.http.post(`${this.apiUrl}/GetOutlookContacts`, json);
  }

  public getAgencySearch(searchString, aType) {
    return this.http.post(`${this.apiUrl}/GetAgencySearch`, {
      searchString: searchString,
      aType: aType
    });
  }

  public getAgencyAdvSearch(Id, pId, Name, pCity, pState, pZip, pCounty, mainPhone, agencyTags, salesArea, agencyStatus, agencyType, aType) {
    return this.http.post(`${this.apiUrl}/GetAgencyAdvSearch`, {
      agencyId: Id,
      parentId: pId,
      agencyName: Name,
      pCity: pCity,
      pState: pState,
      pZip: pZip,
      pCounty: pCounty,
      mainPhone: mainPhone,
      agencyTags: agencyTags,
      salesArea: salesArea,
      agencyStatus: agencyStatus,
      agencyType: agencyType,
      aType: aType
    });

  }
}
