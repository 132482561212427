import { 
  Component, 
  ElementRef,
  Input, 
  OnInit, 
  Renderer2,
  ViewChild 
} from '@angular/core';
declare var $: any;
import * as bootstrap from "bootstrap";
import {
    DocumentManagementService,
    UsersService,
} from "projects/framework/src/lib/services";
import { CookieService } from "ngx-cookie-service";
import { CommonService } from 'projects/framework/src/lib/services/common.service';
import { AgencyManagementService } from 'projects/framework/src/lib/services/agency-management.service';
import { SortService } from '@syncfusion/ej2-angular-grids';
import { GridComponent, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { consoleSandbox } from '@sentry/utils';
//import { DataService } from '../app.service';
import { environment } from "../../environments/environment";
import { Subscription } from "rxjs";
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from "@auth0/auth0-angular";
import { UserService } from "../user-service";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";

interface MyObj {
  AgencyId: string[]
};

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  providers: [SortService],
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  subscription = new Subscription();
  visiblePanel = false;

  searchHistory: any = [];
  agencyHistory: any = [];
  displaySpinner = false;
  visible = false;
  env;
  currentAgencyType = "0";
  currentAgencyTypeDesc = "J&J";
  public animationSettings: Object = {
    effect: "Zoom",
    duration: 400,
    delay: 0,
  };
  centerCenterPosition = { X: "center", Y: "top" };
  dialogPosition = { X: "left", Y: "top" };

  formGroup: FormGroup;

  searchResults: any = [];
  selectionOptions: SelectionSettingsModel;
  selectedRowIndex=0;
  currentAgency="XXXXXX";
  selectedAgency="XXXXXX";
  isAdvSearching=false;

  _searchText;
  set searchText(value) {
    this._searchText = value;
  }
  get searchText() {
    return this._searchText;
  }

  _isSearching: any = { isSearching: false };

  set isSearching(value) {
    this._isSearching = value;
  }
  get isSearching() {
    return this._isSearching;
  }

  _searchItems: any = [];

  set searchItems(value) {

    this._searchItems = value;
  }
  get searchItems() {
    return this._searchItems;
  }

  @Input() displayMenuButton: boolean = false;
  @Input() displaySearch: boolean = true;

  @ViewChild("searchPanel", { static: true }) public searchPanel: ElementRef;
  @ViewChild("grid") public grid: GridComponent; 
  @ViewChild("editorDialog") editorDialog: DialogComponent;

  constructor(
    private commonService: CommonService,
    private amService: AgencyManagementService,
    private documentManagementService: DocumentManagementService,
    private cookie: CookieService,
    //private dataService: DataService,
    private renderer: Renderer2,
    public user: UserService,
    public auth: AuthService,
  ) { 
    this.env = environment;

    this.subscription.add(
      this.renderer.listen("window", "click", (e) => {
        if (
          (e.target.id !== "searchInput" && e.path &&
            !childOf(e.target, this.searchPanel.nativeElement) &&
            e.path.findIndex((d) => d.id == "searchItemsstring_excelDlg") ==
            -1 &&
            e.target.id !== "searchItem") ||
          e.target.id == "recentFile"
        ) {
          this.renderer.addClass(
            this.searchPanel.nativeElement,
            "search-panel-hidden"
          );
          this.searchText = null;
          this.searchItems = [{ files: [] }];
        }
      })
    );

    this.formGroup = new FormGroup({
      agencyCode: new FormControl(),
      parentCode: new FormControl(),
      agencyName: new FormControl(),
      physicalCity: new FormControl(),
      physicalState: new FormControl(),
      physicalCounty: new FormControl(),
      agencyType: new FormControl(),
      agencyTags: new FormControl(),
      physicalZip: new FormControl(),
      agencyStatus: new FormControl(),
      salesArea: new FormControl(),
      mainPhone: new FormControl(),
    });

  }

  ngOnInit(): void {
  }

  editEmailSignatureClick() {
    //this.editorDialog.show();
    this.togglePanel("profile-panel");
  }

  closeEditorDialog() {
    this.editorDialog.hide();
  }

  searchFocus() {
    this.renderer.removeClass(
      this.searchPanel.nativeElement,
      "search-panel-hidden"
    );
  }

  displayPanel(e, id) {
    //e.preventDefault();
    this.togglePanel(id);
    console.log("displayPanel, id = " + id);
  }

  togglePanel(id) {
    let elem = document.getElementById(id);
    if (elem.style.display == "block") {
      elem.style.display = "none";
    } else {
      elem.style.display = "block";
    }
    this.visiblePanel = id;
  }

  async searchKeydown(event: KeyboardEvent) {
    if (!this.searchText) {
      this.searchItems = [];
    } else {
      if (event.key === "Enter") {
        /* User service from employee-client 
        this.searchHistory = await this.userService.saveRecentSearch({
          searchString: this.searchText,
        });*/
        this.searchHistory.unshift(this.searchText);
        this.isAdvSearching=false;
        this.selectionOptions = { type: 'Multiple', enableSimpleMultiRowSelection: false };
        searchString: this.searchText;
        this.displaySpinner = true;

        this.agencySearch(this.searchText);
        $('#searchModal').modal(open);
      }
      if (event.key === "Escape") {
        this.searchText = null;
        this.searchItems = [];
      }
    }
  }
  
  advSearch(args:any): void{
    this.searchResults = [];
    this.isAdvSearching = true;
    $('#searchModal').modal('show');
  }

  rowSelected(args:any): void{
    this.selectedRowIndex = args.row.getAttribute('aria-rowindex');
    this.selectedAgency = args.data.AgencyId;    
  }

  onSelectedAgency(value:string): void {
    console.log("Value = " + value);
    if (value=='J&J') {
        this.currentAgencyType = '0';
        this.currentAgencyTypeDesc = "J&J";
    }
    else if (value=="Torrent") {
        this.currentAgencyType = '1004';
        this.currentAgencyTypeDesc = "Torrent";
    }
    else if (value=="Program") {
        this.currentAgencyType = '1003';
        this.currentAgencyTypeDesc = "Program";
    }

    this.togglePanel('profile-panel');
  }

  agencySearch(searchText) {
    console.log("Curr agency type = " + this.currentAgencyType);
    let sub = this.commonService
      .getAgencySearch(searchText, this.currentAgencyType)
      .subscribe((d: any) => {
        this.searchResults = d;
        sub.unsubscribe;      
      });
    }

  clearClick() {
      this.formGroup.reset();
  }

  recentSearchClicked(sString: string) {
        this.agencySearch(sString);
        $('#searchModal').modal(open);
  }
    
  recentAgencyClicked(aString: string) {
  }

  advancedSearch() {
      this.searchResults = [];

      // Replace nulls with empty strings
      Object.keys(this.formGroup.controls).forEach(key => {
        if (this.formGroup.controls[key].value == null) {
          this.formGroup.controls[key].setValue("");
        }
      })

      let sub = this.commonService
      .getAgencyAdvSearch(
        this.formGroup.controls.agencyCode.value,
        this.formGroup.controls.parentCode.value,
        this.formGroup.controls.agencyName.value,
        this.formGroup.controls.physicalCity.value,
        this.formGroup.controls.physicalState.value,
        this.formGroup.controls.physicalZip.value,
        this.formGroup.controls.physicalCounty.value,
        this.formGroup.controls.mainPhone.value,
        this.formGroup.controls.agencyTags.value,
        this.formGroup.controls.salesArea.value,
        this.formGroup.controls.agencyStatus.value,
        this.formGroup.controls.agencyType.value,
        this.currentAgencyType
        )
      .subscribe((d: any) => {
        this.searchResults = d;
        sub.unsubscribe;      
      });      
  }

  selectAgency(args:any): void{
  }

  adminClick(e) {
      e.preventDefault();
      //this.service.adminClickEvent.emit();
  }

}

function childOf(node, ancestor) {
  var child = node;
  while (child !== null) {
    if (child === ancestor) return true;
    child = child.parentNode;
  }
  return false;
}