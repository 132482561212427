import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/administration/src/environments/environment';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNav implements OnInit {

  public env = environment;
  constructor() { }

  ngOnInit(): void {
  }

}
