<div>
    <div class="close">
        <button class="close" mat-button (click)="close()">X</button>
    </div>

    <br>
    <div class="online-renewal-info">
        <label>Update your email address and name below.</label>
    </div>
    <div class="mt-4 online-renewal-container">            
        <div>
            <label class="online-renewal-label">Does this information apply to all policies?</label>
            <div class="form-field-parent">
                <mat-button-toggle-group [(ngModel)]="applyAllPolicies">
                    <mat-button-toggle value="true">Yes</mat-button-toggle>
                    <mat-button-toggle value="false">No</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="mb-2 mt-4">
                <label class="online-renewal-label">Name</label>
        
                <div class="form-field-parent">
                    <input type="text" class="form-control online-renewal-text" [(ngModel)]="agentName" placeholder="ENTER NAME" required="agentNameRequired"
                        [ngClass]="{'red-border-class': agentNameRequired && (!agentName || agentName == '')}"/>
                </div> 
            </div>
            <div class="mb-2 mt-4">
                <label class="online-renewal-label">Email Address</label>
        
                <div class="form-field-parent">
                    <input type="text" class="form-control" [(ngModel)]="agentEmail" placeholder="ENTER EMAIL" required="agentEmailRequired"
                        [ngClass]="{'red-border-class': agentEmailRequired && (!agentEmail || agentEmail == '')}"/>
                </div> 
            </div>
        </div>
    </div>    
    <div class="mt-4 online-renewal-center">
        <button class="btn btn-outline-secondary rounded-pill me-2" (click)="close()">Cancel</button>
        <button class="btn btn-success rounded-pill" (click)="confirm()"
        [disabled]="agentNameRequired && (!agentName || agentName == '') || (agentEmailRequired && (!agentEmail || agentEmail == ''))">Update</button>
    </div>
</div>
