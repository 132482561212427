 <navbar></navbar>
 
 <div class ="online-renewal-table-container">
    <div class="row online-renewal-header">
        <div class="col-12">
            <button class="backButton" (click)="home()">&larr;</button>
            Online Policy Renewals
        </div>
    </div>

    <!-- LINKS -->
    <div id="left-div">
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="uploadDocuments()" [disabled]="disableUploadDocuments">
            Upload Documents
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="printSelectedForms()">
            Print Selected Forms
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="requestBinding()" [disabled]="disableRequestBinding">
            Request Binding
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="willNotRenew()">
            Will Not Renew
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="requestRevision()" [disabled]="disableRequestRevision">
            Request Revision
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="toggleEmailNotifications('disable')">
            Disable Notices
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="toggleEmailNotifications('enable')">
            Enable Notices
        </button>
        <button type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="updateEmail()">
            Update Agent Email
        </button>
        <button *ngIf="isEmployee" type="button" class="btn btn-link ag-theme-balham online-renewal-links" (click)="deleteRenewal()">
            Delete Quotes
        </button>
    </div>

    <!--SEARCH OPTIONS-->
    <div class="row" [formGroup]="searchForm">
        <div class="row ml-2 mb-2 col-5" *ngIf="isEmployee">
            <input type="text" class="form-control searchOptionText" placeholder="Enter Agency Code" formControlName="agencyId" (keyup.enter)="searchAgency()">
            <button (click)="searchAgency()" class="searchButton"><i class="fa fa-search"></i></button>
        </div>
        <div class="row ml-2 mb-2 col-7">
            <mat-select class="form-control online-renewal-dropdown" placeholder="Show All Quotes" formControlName="searchType">
                <mat-option *ngFor="let opt of showAllOptions" [value]="opt.id" class="online-renewal-dropdown-options"> 
                    {{ opt.optionName }}
                </mat-option>
            </mat-select>

            <mat-select class="form-control ml-3 online-renewal-dropdown" placeholder="Branch Code" formControlName="selectedBranchCodes"
                *ngIf="canSortBranchCodes" multiple #selectBranchCodes>
                <div class="online-renewal-dropdown-options">
                    <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelectedBranchCodes"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllBranchCodesSelection()">SELECT ALL</mat-checkbox>
                    </div>
                </div>
                <mat-option (click)="branchOptionClick()" *ngFor="let opt of branchCodes" [value]="opt.agencyId" class="online-renewal-dropdown-options">
                    {{ opt.agencyId }} - {{ opt.agencyName }}
                </mat-option>
            </mat-select>    
            
            <mat-select class="form-control ml-3 online-renewal-dropdown" placeholder="Sort By" formControlName="selectedSortBy"
                (selectionChange)="getOnlineRenewals()">
                <mat-option *ngFor="let opt of sortByOptions" [value]="opt.id" class="online-renewal-dropdown-options">
                    {{ opt.optionName }}
                </mat-option>
            </mat-select>
            
            <mat-select class="form-control ml-3 online-renewal-dropdown" placeholder="Order" formControlName="selectedOrderBy" 
                [disabled]="disableOrderBy" (selectionChange)="getOnlineRenewals()">
                <mat-option *ngFor="let opt of orderByOptions" [value]="opt.id" class="online-renewal-dropdown-options">
                    <div class="row">
                        <div class="col-9">
                            {{ opt.optionName }}
                        </div>
                        <div class="col-2">
                            <i class="fa fa-sort-amount-asc" *ngIf="opt.id == 1"></i>
                            <i class="fa fa-sort-amount-desc" *ngIf="opt.id == 2"></i>  
                        </div>
                    </div> 
                </mat-option>
            </mat-select>
        </div>
    </div>

    <!--ADDITIONAL SEARCH OPTIONS-->
    <div class="row" *ngIf="showTextInput && isEmployee" [formGroup]="searchForm">
        <div class="col-5 mb-2"></div>
        <div class="row col-6 mb-2 ml-2">
            <input type="text" class="form-control addSearchOptionText" placeholder="{{searchTextPlaceholder}}" formControlName="searchText" (keyup.enter)="getOnlineRenewals()">
            <button (click)="getOnlineRenewals()" class="searchButton"><i class="fa fa-search"></i></button>
        </div>
    </div>
    <div class="row" *ngIf="showDateInput && isEmployee" [formGroup]="searchForm">
        <div class="col-5 mb-2"></div>
        <div class="row col-6 mb-2 ml-2">
            <input type="date" class="form-control addSearchOptionText" placeholder="{{searchDatePlaceholder}}" formControlName="searchDate" (keyup.enter)="getOnlineRenewals()">
            <button (click)="getOnlineRenewals()" class="searchButton"><i class="fa fa-search"></i></button>
        </div>
    </div>

    <div class="row" *ngIf="showTextInput && !isEmployee" [formGroup]="searchForm">
        <div class="row col-6 mb-2 ml-2">
            <input type="text" class="form-control addSearchOptionText" placeholder="{{searchTextPlaceholder}}" formControlName="searchText" (keyup.enter)="getOnlineRenewals()">
            <button (click)="getOnlineRenewals()" class="searchButton"><i class="fa fa-search"></i></button>
        </div>
    </div>
    <div class="row" *ngIf="showDateInput && !isEmployee" [formGroup]="searchForm">
        <div class="row col-6 mb-2 ml-2">
            <input type="date" class="form-control addSearchOptionText" placeholder="{{searchDatePlaceholder}}" formControlName="searchDate" (keyup.enter)="getOnlineRenewals()">
            <button (click)="getOnlineRenewals()" class="searchButton"><i class="fa fa-search"></i></button>
        </div>
    </div>
    
    <!-- RENEWAL POLICIES -->
    <div class="row online-renewal-results-container">
        <table mat-table [dataSource]="renewalDataSource" *ngIf="renewalDataSource != null">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox class="custom-frame" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox class="custom-frame" (click)="$event.stopPropagation()"
                        (change)="$event ? rowClick(selection, row): null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="policyNumber">
                <th mat-header-cell *matHeaderCellDef>Policy Number</th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.policyNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="agencyId">
                <th mat-header-cell *matHeaderCellDef> Agency Code </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.agencyId }}</td>
            </ng-container>

            <ng-container matColumnDef="insuredName">
                <th mat-header-cell *matHeaderCellDef> Insured Name </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.insuredName }}</td>
            </ng-container>

            <ng-container matColumnDef="transactionDate">
                <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.transactionDate | date: 'MM/dd/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="expirationDate">
                <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.expirationDate | date: 'MM/dd/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="revisionId">
                <th mat-header-cell *matHeaderCellDef> Renewal Revision # </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.revisionId }}</td>
            </ng-container>

            <ng-container matColumnDef="agentInfo">
                <th mat-header-cell *matHeaderCellDef> Agent Information </th>
                <td mat-cell *matCellDef="let renewal">
                    <table mat-cell>
                        <tr>
                            <td>
                                {{ renewal.agentName }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ renewal.agentEmail }}
                            </td>
                        </tr>
                    </table>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.status }}</td>
            </ng-container>

            <ng-container matColumnDef="basePremium">
                <th mat-header-cell *matHeaderCellDef> Total Premium </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.totalPremium }}</td>
            </ng-container>

            <ng-container matColumnDef="underwriterInfo">
                <th mat-header-cell *matHeaderCellDef> Underwriter Information </th>
                <td mat-cell *matCellDef="let renewal">
                    <table>
                        <tr>
                            <td>
                                {{ renewal.underwriterName }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ renewal.underwriterEmail }}
                            </td>
                        </tr>
                    </table>
                </td>
            </ng-container>

            <ng-container matColumnDef="quoteDescription">
                <th mat-header-cell *matHeaderCellDef> Quote Description </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.quoteDescription }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="renewalDisplayedColumns; sticky: true" class="text-right desc-col"></tr>
            <tr mat-row *matRowDef="let renewalRow; columns: renewalDisplayedColumns" class="text-right desc-col">
            </tr>
        </table>
    </div>
</div>

<ng-snotify class="material"></ng-snotify>
<ngx-spinner name="loading" [fullScreen]="false" type="ball-circus" size="default">
</ngx-spinner>