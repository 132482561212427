
<div class="content">
  <div *ngIf="(auth.isAuthenticated$ | async) === false" class="not-logged-in">
    <div class="centered">
      <table class="w-100 text-center">
        <tr>
          <td><img src="https://content.mga-portal.com/images/jj_logo.png" alt=""></td>
        </tr>
        <tr>
          <td><button class="btn btn-sm btn-success px-5 py-2" (click)="login()">Login</button></td>
        </tr>
        <!-- <tr>
          <td><button class="btn btn-link mt-1" (click)="auth.loginWithRedirect()">Not an employee?</button></td>
        </tr> -->
      </table>
    </div>
  </div>

  <router-outlet *ngIf="(auth.isAuthenticated$ | async)"></router-outlet>
</div>
