<div class="container-fluid">
    <div class="row h-100">
      <div class="col h-100">
        <div class="horizontal-row">
          <div class="file-name">
            <label class="online-renewal-label">{{file.name.split(".")[0]}}</label>
          </div>
          <div>
            <button class="btn btn-success rounded-pill" (click)="confirm()">Continue</button>
          </div>
        </div>
        <iframe id="pdfFrame" class="pdf-frame"></iframe>
      </div>
  </div>
  </div>
  