import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class OnlineRenewalsService2 {
  apiUrl;
  
  @Output() formEditCompleteEvent = new EventEmitter();

  constructor(
    private http:HttpClient, 
    private auth:AuthService)  
  { 
    this.apiUrl = `${environment.apiUrl}/onlinerenewals`;
  }

  willNotRenew(policies){

    console.log("help")
    console.log(`${this.apiUrl}/WillNotRenew`)
    console.log(`${environment.apiUrl}/documentmanagement/CompleteWorkflowTask`)
    const postData: { id: string, value: any }[] = [
      { 'id': 'isTesting', 'value': environment.production },
      { 'id': 'batch', 'value': policies }
    ];

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${environment.apiUrl}/documentmanagement/CompleteWorkflowTask`, postData); 

  }
}
