import { ModuleWithProviders, NgModule } from "@angular/core";
import { DocumentManagementService } from "./document-management.service";
import { AgencyManagementService } from "./agency-management.service";
import { UsersService } from "./user.service";
import { ViewerService } from "./viewer.service";
import { CommonService } from "./common.service";
import { SecurityService } from "./security.service";
import { AdministrationService } from "./administration.service";
import { DashboardService } from "./dashboard.service";
import { CookieService } from "ngx-cookie-service";
import { LocalAdminService } from "./local-admin.service";
import { PopoutService } from "./popout.service";
import { OnlineRenewalsService } from "./online-renewals.service";

// @NgModule()
// export class SharedServicesModule {
//   static forRoot(environment): ModuleWithProviders<SharedServicesModule> {
//     return {
//       ngModule: SharedServicesModule,
//       providers: [
//         { provide: "environment", useValue: environment },
//         DocumentManagementService,
//         ViewerService,
//         AgencyManagementService,
//         UsersService,
//         CommonService,
//         SecurityService,
//         AdministrationService,
//         DashboardService,
//         CookieService,
//         SharedService,
//         LocalAdminService
//       ],
//     };
//   }
// }
@NgModule()
export class SharedServicesModule {
  static forRoot(environment): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        { provide: "environment", useValue: environment },
        DocumentManagementService,
        ViewerService,
        AgencyManagementService,
        UsersService,
        CommonService,
        SecurityService,
        AdministrationService,
        DashboardService,
        CookieService,
        LocalAdminService,
        PopoutService,
        OnlineRenewalsService,
      ],
    };
  }
}