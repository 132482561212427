import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { LocalStorageTypes } from "projects/framework/src/lib/enums/local-storage-types";
import { Subscription } from "rxjs";
import {
  DocumentManagementService,
  UsersService,
} from "./../../../framework/src/lib/services/index";

import Swal from "sweetalert2";
import { AppService } from "./app.service";
import { ColumnController } from "ag-grid-community";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  subscription: Subscription = new Subscription();

  defaultUserType;

  get userId() {
    var user = JSON.parse(localStorage.getItem(LocalStorageTypes.UserProfile));
    return user.userId;
  }

  constructor(
    private auth: AuthService,
    private app: AppService,
    private service: DocumentManagementService,
    private user: UsersService,
    private cookie: CookieService
  ) {}

  init(userProfile: any) {
    if (userProfile) {
      let json = userProfile.user_metadata;
      json.sub = userProfile.sub;
      json.email = userProfile.email;
      json.name = userProfile.name;
      json.picture = userProfile.picture;

      localStorage.setItem("userProfile", JSON.stringify(json));
    } 
  }

  async updateSettings() {
    var promise = new Promise((resolve, reject) => {
      var profile = JSON.parse(localStorage.getItem("userProfile"));

      var sub = this.service
        .getUserSettings(profile.userId)
        .subscribe((d: any) => {
          let searches;
          let files;
          let my;

          if (d) {
            searches = d.settings.find((z) => z.name == "recentSearches");
            if (searches) {
              localStorage.setItem(
                searches.name,
                JSON.stringify(searches.json)
              );
            } else {
              localStorage.removeItem("recentSearches");
            }

            files = d.settings.find((z) => z.name == "recentFiles");
            if (files) {
              localStorage.setItem(files.name, JSON.stringify(files.json));
            } else {
              localStorage.removeItem("recentFiles");
            }

            my = d.settings.find((z) => z.name == "myFiles");
            if (my) {
              localStorage.setItem(my.name, JSON.stringify(my.json));
            } else {
              localStorage.removeItem("myFiles");
            }
          } else {
            localStorage.removeItem("recentSearches");
            localStorage.removeItem("recentFiles");
            localStorage.removeItem("myFiles");
          }

          sub.unsubscribe();

          resolve({
            recentSearches: searches?.json,
            recentFiles: files?.json,
            myFiles: my?.json,
          });
        });
    });

    return promise;
  }

  accessDenied() {
    Swal.fire(
      "Access Denied",
      "There appears to be a problem with your security settings.  Please place a JIRA item to have this resolved.",
      "error"
    );
  }

  loadSettings(settings) {
    if (settings) {
      settings.forEach((setting) => {
        localStorage.setItem(setting.name, JSON.stringify(setting.json));
      });
    }
  }

  get recentFiles(): [] {
    var json = localStorage.getItem(LocalStorageTypes.RecentFiles);

    if (json) {
      return JSON.parse(json);
    }
  }

  get recentSearches(): [] {
    var rs = localStorage.getItem(LocalStorageTypes.RecentSearches);
    var json: any =
      rs && rs != undefined
        ? localStorage.getItem(LocalStorageTypes.RecentSearches)
        : [];

    if (json && json != undefined) {
      return JSON.parse(json);
    }

    return [];
  }

  get myFiles(): [] {
    var json = localStorage.getItem(LocalStorageTypes.MyFiles);
    if (json && json != undefined) {
      return JSON.parse(json);
    }

    return [];
  }

  get profile(): any {
    var json = localStorage.getItem(LocalStorageTypes.UserProfile);

    if (json) {
      return JSON.parse(json);
    }

    return {};
  }

  saveMyFile(file): [] {
    var json = JSON.parse(localStorage.getItem(LocalStorageTypes.MyFiles));
    if (json) {
      let selectedfile = json
        .filter((a) => a != null && a != undefined)
        .find((d) => d.fileId == file.fileId);
      if (!selectedfile) {
        json.unshift(file);
        if (json.length > 25) {
          json.shift();
        }
      }
    } else {
      json = [file];
    }

    localStorage.setItem(LocalStorageTypes.MyFiles, JSON.stringify(json));
    this.saveUserSettings(LocalStorageTypes.MyFiles, JSON.stringify(json));
    return json;
  }

  updateFiles(files) {
    localStorage.setItem(LocalStorageTypes.MyFiles, JSON.stringify(files));
    this.saveUserSettings(LocalStorageTypes.MyFiles, JSON.stringify(files));
    return files;
  }

  saveUserSettings(name, json) {
    var user = JSON.parse(localStorage.getItem(LocalStorageTypes.UserProfile));

    let model = { name: name, userId: user.userId, json: json };
    var sub = this.service.saveUserSettings(model).subscribe((success) => {
      sub.unsubscribe();
    });
  }

  saveGeneralSettings(name, json) {
    var user = JSON.parse(localStorage.getItem(LocalStorageTypes.UserProfile));

    let model = { name: name, userId: user.userId, json: json };
    var sub = this.service.saveGeneralSettings(model).subscribe((success) => {
      sub.unsubscribe();
    });
  }
}