import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { BindingRequest } from "projects/online-renewals/src/app/models/binding-request";
import { CancellationRequest } from "projects/online-renewals/src/app/models/cancellation-request";
import { OnlineRenewalsPolicy } from "projects/online-renewals/src/app/models/online-renewals-policy";
import { PolicyDetailSearchArguments, PolicySearchArguments } from "projects/online-renewals/src/app/models/policy-search";
import { Observable } from 'rxjs';
import { PolicyStatus } from "../enums/policy-status";
import { AttachmentFile } from "../models/attachment-file";

@Injectable()
export class OnlineRenewalsService {
    apiUrl: string;
    isTesting: boolean;

    @Output() formEditCompleteEvent = new EventEmitter();
    
    constructor(
        @Inject("environment") private environment,
        private http: HttpClient) {
            this.apiUrl = `${environment.apiUrl}/OnlineRenewals`;
            this.isTesting = !environment.production;
        }

    public executeQuery(args: any){
        return this.http.post(`${this.apiUrl}/ExecuteQuery`, args);    
    }

    public GetOnlineRenewals(args: any){
        return this.http.post(`${this.apiUrl}/GetOnlineRenewals`, args);    
    }
    
    public ToggleEmailNotifications(args: any){
        return this.http.post(`${this.apiUrl}/ToggleEmailNotifications`, args);
    }

    public UpdateEmail(args: any){
        return this.http.post(`${this.apiUrl}/UpdateEmail`, args);
    }

    public saveOnlineRenewal(args: any){    
        return this.http.post(`${this.apiUrl}/SaveOnlineRenewal`, args);    
    }

    public getEmailTemplateData(args: any){
        return this.http.post(`${this.apiUrl}/GetEmailTemplateData`, args);    
    }

    public getRenewalDocuments(args: any) {
        return this.http.post(`${this.apiUrl}/GetRenewalDocuments`, args);
      }
}