import { Injectable,Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UsersService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/user`;
  }

  public getMicrosoftMsalToken() {
    let json = JSON.parse(localStorage.getItem("userProfile"));
    let userId = json.sub;
    return this.http.get(`${this.apiUrl}/GetMicrosoftMsalToken?userId=${userId}`);
  }

  public saveRecentSearch(json) {
    return this.http.post(`${this.apiUrl}/SaveRecentSearch`, json).toPromise();
  }

  public saveRecentFile(json) {
    return this.http.post(`${this.apiUrl}/SaveRecentFile`, json);
  }

  public saveEmailSignature(json) {
    return this.http.post(`${this.apiUrl}/SaveEmailSignature`, json);
  }

  public getEmailSignature() {
    return this.http.post(`${this.apiUrl}/GetEmailSignature`, {});
  }
}
