<div class="navbar navbar-inverse navbar-fixed-top border-bottom">
  <div class="container-fluid">
    <div class="nav-left-column">
      <ul>
        <li class="dropdown li-icon-button mr-2" *ngIf="(auth.isAuthenticated$ | async) && displayMenuButton===true">
          <a href="#" class="dropdown-toggle profile menu-button" data-toggle="dropdown">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <img src="https://storagejjdeveast.blob.core.windows.net/cdn/images/logo32.png"/>
        </li>

        <li class="title">
          Johnson & Johnson
        </li>
        <li class="mr-2" *ngIf="(auth.isAuthenticated$ | async) && displaySearch===true">
          <div class="input-group input-group-sm">
            <input id="searchInput" class="form-control search-input" style="width: 40vw" placeholder="Search">
          </div>
        </li>
      </ul>

    </div>

    <div class="nav-right-column">
      <ul class="float-right">
        <!-- notifications -->
        <!-- <li class="dropdown li-icon-button" *ngIf="(auth.isAuthenticated$ | async)">
          <a href="#" class="dropdown-toggle profile icon-button" data-toggle="dropdown">
            <i class="fa fa-bell" aria-hidden="true"></i>
          </a>
        </li> -->

        <!-- feedback -->
        <!-- <li class="dropdown li-icon-button" *ngIf="(auth.isAuthenticated$ | async)">
          <a href="#" class="dropdown-toggle profile icon-button" data-toggle="dropdown">
            <i class="fa fa-comment" aria-hidden="true"></i>
          </a>
        </li> -->

        <!-- settings -->
        <!-- <li class="dropdown li-icon-button mr-3" *ngIf="(auth.isAuthenticated$ | async)">
          <a href="#" class="dropdown-toggle profile icon-button">
            <i class="fa fa-cog" aria-hidden="true"></i>
          </a>
        </li> -->

        <!-- <li>
          <button class="btn btn-sm btn-danger" (click)="auth.login()" *ngIf="!(auth.isAuthenticated$ | async)">Log In</button>
        </li>  -->

        <li *ngIf="(auth.isAuthenticated$ | async)">
          <a href="#" class="profile" (click)="displayPanel($event,'profile-panel')">
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <i class="fa fa-user profile-icon" aria-hidden="true"></i>
                </td>
                <td>
                  <div id="profile">
                    <span class="profile-name">{{user?.profile?.name}}</span>
                    <!-- <span *ngIf="auth.profile?.agencyCode" class="profile-agency-code">AGENCY CODE: {{auth.profile?.agencyCode}}</span> -->
                  </div>
                </td>
              </tr>
            </table>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div id="profile-panel" class="profile-panel" *ngIf="user?.profile">
  <ul>
    <li class="border-bottom mb-3">
      <div class="navbar-login">
        <table class="w-100">
          <tr>
            <td style="width: 60px;">
              <i class="fa fa-user" style="font-size: 45px;" aria-hidden="true"></i>
            </td>
            <td>
              <strong>{{ user?.profile?.name}}</strong>
              <br />
              <span class="text-left small">{{  user?.profile?.email }}</span>
            </td>
          </tr>
        </table>
      </div>
    </li>

    <li>
      <div class="navbar-login navbar-login-session">
        <div class="row">
          <div class="col-lg-12">
            <p>
              <!-- <button class="btn btn-sm btn-outline-dark w-100" (click)="auth.logout()">Change / Reset My
                Password</button> -->
            </p>
          </div>
        </div>
      </div>
    </li>

    <li class="border-top">
      <div class="navbar-login navbar-login-session">
        <div class="row">
          <div class="col-lg-12">
            <p>
              <button class="btn btn-danger mt-3 w-100" (click)="auth.logout()">Sign Out</button>
            </p>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<div #searchPanel class="search-panel search-panel-hidden"></div>
