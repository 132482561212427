import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../user-service';

@Component({
  selector: 'app-delete-renewal-dialog',
  templateUrl: './delete-renewal-dialog.component.html',
  styleUrls: ['./delete-renewal-dialog.component.scss']
})
export class DeleteRenewalDialogComponent implements OnInit {

  policies: any = [];
  renewalDisplayedColumns: string[] = [ 'policyNumber', 'insuredName', 'expirationDate', 'basePremium' ];
  renewalDataSource;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeleteRenewalDialogComponent>, public user: UserService) { 
    this.policies = data.policies;
    this.renewalDataSource = new MatTableDataSource(this.policies);
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  confirm(){
    for(let policy of this.policies){
      for(let policy of this.policies){
        policy["isDeleted"] = true;
        policy["userId"] = this.user.profile.userId;
        policy["statusId"] = 0;
      }
    }
    this.dialogRef.close(this.policies);
  }
}
