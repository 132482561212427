import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LocalAdminService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.localApiUrl}/admin`;
  }

  public getStateList() {
    return this.http.get(`${this.apiUrl}/GetStateList`);
  }

  public getCompanyList(division) {
    return this.http.get(
      `${this.apiUrl}/GetCompaniesList?division=${division}`
    );
  }

  public getFormsList(formNumber) {
    return this.http.get(
      `${this.apiUrl}/GetFormsList?formNumber=${formNumber}`
    );
  }

  public savePolicyFormRules(model) {
    return this.http.post(`${this.apiUrl}/SavePolicyFormRules`, model);
  }

  public getPolicyFormRules() {
    return this.http.get(`${this.apiUrl}/GetPolicyFormRules`);
  }

  public deletePolicyFormRules(model) {
    return this.http.post(`${this.apiUrl}/DeletePolicyFormRules`, model);
  }

  public getPolicyFormRuleCriteria() {
    return this.http.get(`${this.apiUrl}/GetPolicyFormRuleCriteria`);
  }

  public getPolicyFormRuleComparison(model) {
    return this.http.post(
      `${this.apiUrl}/GetPolicyFormRuleComparison?companyId`,
      model
    );
  }
}
