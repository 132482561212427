<div>
    <div class="close">
        <button class="close" mat-button (click)="close()">X</button>
    </div>
    <div>
        <label class="online-renewal-label">
            Would you like to {{ noticeType }} reminder notices for all future policies or just the selected policies?
        </label>
    </div>
    <div class="mt-4 online-renewal-container">
        <div class="form-field-parent mt-2 online-renewal-options">
            <mat-radio-group aria-label="Select an option" style="display: flex; flex-direction: column" required="applyAllPoliciesRequired">
            <mat-radio-button value="1" [disabled]="!isAdministrator" (change)="toggleForAll(true)">All Future Policies and Selected Policies</mat-radio-button>
            <mat-radio-button value="2" (change)="toggleForAll(false)">Just Selected Policies</mat-radio-button>
          </mat-radio-group>
        </div>
    </div>    
    <div class="mt-4 online-renewal-center">
        <button class="btn btn-outline-secondary rounded-pill me-2" (click)="close()">Cancel</button>
        <button class="btn btn-success rounded-pill" (click)="confirm()">Confirm</button>
    </div>
</div>


