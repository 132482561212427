import { InjectionToken } from '@angular/core';

export interface PopoutData {
  modalName: string;
}

export const POPOUT_MODAL_DATA = new InjectionToken<PopoutData>('POPOUT_MODAL_DATA');

export enum PopoutModalName {
  'composeEmail' = 'COMPOSE_EMAIL'
}

export let POPOUT_MODALS = {
  FileClearance: {}
};