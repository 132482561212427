import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-viewer-dialog',
  templateUrl: './form-viewer-dialog.component.html',
  styleUrls: ['./form-viewer-dialog.component.scss']
})
export class FormViewerDialogComponent implements OnInit {
  
  fileList: File[];
  file: File;
  fileData: any[] = [];

  constructor(private dialogRef: MatDialogRef<FormViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any){
    this.fileList = this.dialogData.fileList;
  }  

  ngOnInit() {
    this.fileList.forEach(file => {
      this.fileData.push(
        {
          file: file,
          name: file.name,
          formsSource: "Attachment"
        }
      )
    });
    this.setActiveFile(this.fileList[0]);
  }

  setActiveFile(file: File){
    if (this.file == null || this.file.name != file.name){
      this.file = file;
      this.refreshForm();
    }
  }

  refreshForm(){
    let pdfSrc = URL.createObjectURL(this.file);
    let iframe = document.getElementById('pdfFrame');
    iframe.setAttribute('src', pdfSrc);
  }

  confirm(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close();
  }
}
