<div>
    <div class="close">
        <button class="close" mat-button (click)="close()">X</button>
    </div>
    <label class="online-renewal-info">You are choosing to delete the following policies from Online Renewals. The policies will be removed from view and all tasks associated with them will be unassigned.</label>
    <div class="mt-4 online-renewal-container">            
        <table mat-table [dataSource]="renewalDataSource" *ngIf="renewalDataSource != null">
            <ng-container matColumnDef="policyNumber">
                <th mat-header-cell *matHeaderCellDef>Policy Number</th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.policyNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="insuredName">
                <th mat-header-cell *matHeaderCellDef> Insured Name </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.insuredName }}</td>
            </ng-container>

            <ng-container matColumnDef="expirationDate">
                <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.expirationDate | date: 'MM/dd/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="basePremium">
                <th mat-header-cell *matHeaderCellDef> Total Premium </th>
                <td mat-cell *matCellDef="let renewal">{{ renewal.totalPremium }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="renewalDisplayedColumns" class="text-right desc-col"></tr>
            <tr mat-row *matRowDef="let renewalRow; columns: renewalDisplayedColumns" class="text-right desc-col">
            </tr>
        </table>
    </div>    
    <div class="col-12 mt-4 online-renewal-center">
        <button class="btn btn-outline-secondary rounded-pill me-2" (click)="close()">Cancel</button>
        <button class="btn btn-success rounded-pill" (click)="confirm()">Continue</button>
    </div>
</div>
