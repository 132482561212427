import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../user-service';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';

@Component({
  selector: 'app-will-not-renew-dialog',
  templateUrl: './will-not-renew-dialog.component.html',
  styleUrls: ['./will-not-renew-dialog.component.scss']
})
export class WillNotRenewDialogComponent implements OnInit {
  policies: any = [];
  otherReason: string;
  wording: string;
  wordingData;

  reasonOptions;

  validationRules;
  reasonRequired: boolean = false;
  commentsRequired: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<WillNotRenewDialogComponent>, private onlineRenewals: OnlineRenewalsService, public user: UserService) { 
    this.policies = data.policies;
  }

  ngOnInit(): void {
    this.getDropDownData();
  }

  getDropDownData(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetDropDownData', json: { dropDownOption: "willNotRenewOptions" }}).subscribe(res => {
      this.reasonOptions = res;
      sub.unsubscribe();
    });

    var sub2 = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetScreenWording', json: { screenType: "Will Not Renew" }}).subscribe(d => {
      this.wordingData = d;
      this.wording = this.wordingData.wording;
      sub2.unsubscribe();
    });
  }

  getValidationRules(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetValidationRules', json: { pageName: 'willNotRenew' }}).subscribe(res => {
      this.validationRules = res;
      if (this.validationRules.filter(p => p.controlName == 'reason').length > 0){
        this.reasonRequired = this.validationRules.filter(p => p.controlName == 'reason')[0].isRequired;
      }
      if (this.validationRules.filter(p => p.controlName == 'comments').length > 0){
        this.commentsRequired = this.validationRules.filter(p => p.controlName == 'comments')[0].isRequired;
      }
      sub.unsubscribe();
    });
  }

  close(){
    this.dialogRef.close();
  }

  confirm(){
    var policyData = [];
    for (let policy of this.policies){
      policy["willNotRenew"] = true;
      policy["statusId"] = 10;
      policy["userId"] = this.user.profile.userId;
    }

    this.dialogRef.close(this.policies);
  }
}
