import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class DashboardService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/dashboard`;
  }

  public getReportsList() {
    return this.http.get(`${this.apiUrl}/GetReportsList`);
  }

  public getData(json) {
    return this.http.post(`${this.apiUrl}/GetData`, json);
  }
}