import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterSettingsModel, GridComponent, RecordDoubleClickEventArgs, TextWrapSettings } from '@syncfusion/ej2-angular-grids';
import { OnlineRenewalsService } from 'projects/framework/src/lib/services/online-renewals.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { WillNotRenewDialogComponent } from './will-not-renew-dialog/will-not-renew-dialog.component';
import { UpdateEmailDialogComponent } from './update-email-dialog/update-email-dialog.component';
import { ToggleEmailNotificationsDialogComponent } from './toggle-email-notifications-dialog/toggle-email-notifications-dialog.component';
import { UploadDocumentsDialogComponent } from './upload-documents-dialog/upload-documents-dialog.component';
import { DeleteRenewalDialogComponent } from './delete-renewal-dialog/delete-renewal-dialog.component';
import { OnlineRenewalsService2 } from '../online-renewals.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { AuthService } from 'projects/framework/src/lib/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavbarComponent } from '../navbar/navbar.component';
import { UserService } from '../user-service';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SelectionModel } from '@angular/cdk/collections';
import { AppService } from '../app.service';
import * as FileSaver from "file-saver-es";
import { SnotifyService, SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'online-policy-renewals',
  templateUrl: './online-policy-renewals.component.html',
  styleUrls: ['./online-policy-renewals.component.scss']
})

export class OnlinePolicyRenewalsComponent implements OnInit { 
  @ViewChild('selectBranchCodes') selectBranchCodes: MatSelect;

  subscription = new Subscription();
  env;
  rowData: Object[];
  searchForm: FormGroup;
  inputForm: FormGroup;
  isEmployee: Boolean = false;
  canSortBranchCodes: Boolean = true;
  allSelectedBranchCodes: Boolean = false;
  
  onlineRenewalPolicies;
  renewalDisplayedColumns: string[] = [ 'select', 'policyNumber', 'agencyId', 'insuredName', 'transactionDate', 'expirationDate', 
  'revisionId', 'agentInfo', 'status','basePremium', 'underwriterInfo', 'quoteDescription' ];
  renewalDataSource;
  selection = new SelectionModel<any>(true, []);
  selectedRows;

  showAllOptions;
  sortByOptions;
  orderByOptions;
  branchCodes;
  searchTextPlaceholder;
  searchDatePlaceholder;
  showTextInput: Boolean = false;
  showDateInput: Boolean = false;
  disableOrderBy: Boolean = true;
  selectedSortBy;
  selectedOrderBy;
  disableUploadDocuments: Boolean = true;
  disableRequestBinding: Boolean = true;
  disableRequestRevision: Boolean = true;


  constructor(private service: OnlineRenewalsService2, private modalService: NgbModal, private onlineRenewals: OnlineRenewalsService, private dialog: MatDialog, private spinner: NgxSpinnerService, public user: UserService, public appService: AppService, private toastr: SnotifyService){  
  }

  ngOnInit(): void {
    if (this.user.profile.userType == "employee") {
      this.isEmployee = true;
    }

    this.searchForm = new FormGroup({
      agencyId: new FormControl(),
      searchText: new FormControl(),
      searchDate: new FormControl(),
      searchType: new FormControl(),
      selectedBranchCodes: new FormControl(),
      selectedSortBy: new FormControl(),
      selectedOrderBy: new FormControl()
    });

    this.getDropDownData();
    this.searchAgency();
    this.getOnlineRenewals();

    this.searchForm.get('searchType').valueChanges.subscribe(item => {
      if (item == "1") {
        this.showTextInput = true;
        this.showDateInput = false;
        this.searchDatePlaceholder = "";
        this.searchTextPlaceholder = "Enter Policy Number";
      }
      else if (item == "2") {
        this.showTextInput = false;
        this.showDateInput = true;
        this.searchDatePlaceholder = "Enter Transaction Date";
        this.searchTextPlaceholder = "";
      }
      else if (item == "3") {
        this.showTextInput = true;
        this.showDateInput = false;
        this.searchDatePlaceholder = "";
        this.searchTextPlaceholder = "Enter Insured Name";
      }
      else if (item == "4") {
        this.showTextInput = false;
        this.showDateInput = true;
        this.searchDatePlaceholder = "Enter Expiration Date";
        this.searchTextPlaceholder = "";
      }
      else {
        this.showTextInput = false;
        this.showDateInput = false;
        this.searchDatePlaceholder = "";
        this.searchTextPlaceholder = "";
      }
    });

    this.searchForm.get('selectedSortBy').valueChanges.subscribe(item => {
      if (item && item != "0"){
        this.disableOrderBy = false;
      }
      else {
        this.disableOrderBy = true;
      }

      if (item){
        this.selectedSortBy = item;
      }
    });

    this.searchForm.get('selectedOrderBy').valueChanges.subscribe(item => {
      if (item){
        this.selectedOrderBy = item;
      }
    });
  }

  populateBranchCodes() {
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetAgencyBranchCodes', json: { user: this.user.profile, agencyId: this.searchForm.value.agencyId }}).subscribe(res => {
      this.branchCodes = res;
      if (this.branchCodes.length > 1){
        this.canSortBranchCodes = true;
      }
      else {
        this.canSortBranchCodes = false;
      }
      sub.unsubscribe();
    });
  }

  getDropDownData(){
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetDropDownData', json: { dropDownOption: "showAllOptions" }}).subscribe(res => {
      this.showAllOptions = res;
      sub.unsubscribe();
    });

    var sub2 = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetDropDownData', json: { dropDownOption: "sortByOptions" }}).subscribe(res => {
      this.sortByOptions = res;
      sub2.unsubscribe();
    });

    var sub3 = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetDropDownData', json: { dropDownOption: "orderByOptions" }}).subscribe(res => {
      this.orderByOptions = res;
      sub3.unsubscribe();
    });
  }

  getOnlineRenewals() {
    this.spinner.show("loading");
    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetOnlineRenewals', json: { user: this.user.profile, searchData: this.searchForm.value, sortBy: this.selectedSortBy, orderBy: this.selectedOrderBy }}).subscribe(res => {
      this.selection.clear();
      this.onlineRenewalPolicies = res;
      this.renewalDataSource = new MatTableDataSource(this.onlineRenewalPolicies);
      this.spinner.hide("loading");
      sub.unsubscribe();
    });
  }

  searchAgency () {    
    this.spinner.show("loading");
    this.populateBranchCodes();

    var sub = this.onlineRenewals.executeQuery({ spName: 'sp_or_GetOnlineRenewals', json: { user: this.user.profile, searchData: this.searchForm.value }}).subscribe(res => {
      this.selection.clear();
      this.onlineRenewalPolicies = res;
      this.renewalDataSource = new MatTableDataSource(this.onlineRenewalPolicies);
      this.spinner.hide("loading");
      sub.unsubscribe();
    });
  }

  home() {
  }

  toggleAllBranchCodesSelection() {
    if (this.allSelectedBranchCodes) {
      this.selectBranchCodes.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranchCodes.options.forEach((item: MatOption) => item.deselect());
    }
    this.getOnlineRenewals();
  }
  
  branchOptionClick() {
    let newStatus = true;
    this.selectBranchCodes.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedBranchCodes = newStatus;
    this.getOnlineRenewals();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.renewalDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.renewalDataSource.data.forEach(row => this.selection.select(row));
  }

  rowClick(selection, row) {
    selection.toggle(row);

    this.disableUploadDocuments = true;
    this.disableRequestBinding = true;
    this.disableRequestRevision = true;

    if (selection.selected.filter(p => p.allowUploadDocuments == true).length > 0){
      this.disableUploadDocuments = false;
    }    
    if (selection.selected.filter(p => p.allowBinding == true).length > 0){
      this.disableRequestBinding = false;
    }
    if (selection.selected.filter(p => p.allowRequestRevision == true).length > 0){
      this.disableRequestRevision = false;
    }
  }

  uploadDocuments(){
    if (this.selection.selected.filter(p => p.allowUploadDocuments == true).length == 1){      
      var policy = this.selection.selected.filter(p => p.allowUploadDocuments == true);
      let dialogRef = this.dialog.open(UploadDocumentsDialogComponent, {
        width: '800px',
        height: '600px',
        data: {
          policy: policy,
          title: 'Upload Documents',
          statusId: 3
        }
      });
      
      dialogRef.afterClosed().subscribe(docs => {  
        this.spinner.show("loading");    
        if (docs){
          var sub = this.onlineRenewals.saveOnlineRenewal(docs).subscribe(d => {     
            this.selection.clear();
            this.searchForm.reset();
            this.getOnlineRenewals();  
            this.spinner.hide("loading");
            sub.unsubscribe();
          });
        }
        else {
          this.spinner.hide("loading");
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account from the below list before choosing to Upload Documents*", "Upload Documents", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  printSelectedForms(){  
    if (this.selection.selected.length > 0){
      this.spinner.show("loading"); 
      var policy = this.selection.selected;
      let sub = this.onlineRenewals
        .getRenewalDocuments(policy)
        .subscribe((success: any) => {
          success.forEach(doc => {
            let blob = this.appService.CreateBlob(
              doc.fileBytes,
              doc.mimeType
            );
            FileSaver.saveAs(blob, doc.fileName);
          });
          sub.unsubscribe();
          this.spinner.hide("loading");
        });
    }
    else{
      this.toastr.warning("*Please select the account(s) from the below list before choosing to Print Selected Forms*", "Print Forms", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  requestBinding(){
    if (this.selection.selected.filter(p => p.allowBinding == true).length == 1){      
      var policy = this.selection.selected.filter(p => p.allowBinding == true);
      let dialogRef = this.dialog.open(UploadDocumentsDialogComponent, {
        width: '800px',
        height: '600px',
        data: {
          policy: policy,
          title: 'Request Binding',
          statusId: 7
        }
      });
      
      dialogRef.afterClosed().subscribe(docs => {  
        this.spinner.show("loading");    
        if (docs){
          var sub = this.onlineRenewals.saveOnlineRenewal(docs).subscribe(d => {     
            this.selection.clear();  
            this.searchForm.reset();
            this.getOnlineRenewals();  
            this.spinner.hide("loading");
            sub.unsubscribe();
          });
        }
        else {
          this.spinner.hide("loading");
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account from the below list before choosing to Request Binding*", "Request Binding", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  willNotRenew(){
    if (this.selection.selected.filter(p => p.allowWillNotRenew == true).length > 0){   
      var selectedRows = this.selection.selected.filter(p => p.allowWillNotRenew == true);
      let dialogRef = this.dialog.open(WillNotRenewDialogComponent, {
        width: '800px',
        data: {
          policies: selectedRows
        }
      });
      
      dialogRef.afterClosed().subscribe(notRenewPolicies => {
        if (notRenewPolicies && notRenewPolicies.length >= 1){
          this.spinner.show("loading");
          let observables: Array<Observable<any>>  = [];
          for(let policy of notRenewPolicies){
              observables.push(this.onlineRenewals.saveOnlineRenewal(policy))
          }

          forkJoin(observables).subscribe(dataArray => {
              this.selection.clear();    
              this.searchForm.reset();          
              this.getOnlineRenewals();
              this.spinner.hide("loading");
          });
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account(s) from the below list before choosing Will Not Renew*", "Will Not Renew", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  requestRevision(){
    if (this.selection.selected.filter(p => p.allowRequestRevision == true).length == 1){  
      var policy = this.selection.selected.filter(p => p.allowRequestRevision == true);
      let dialogRef = this.dialog.open(UploadDocumentsDialogComponent, {
        width: '800px',
        height: '600px',
        data: {
          policy: policy,
          title: 'Request Revision',
          statusId: 6
        }
      });
      
      dialogRef.afterClosed().subscribe(revisionPolicies => { 
        this.spinner.show("loading");     
        if (revisionPolicies){
          var sub = this.onlineRenewals.saveOnlineRenewal(revisionPolicies).subscribe(d => {              
            this.selection.clear();
            this.searchForm.reset();
            this.getOnlineRenewals();
            this.spinner.hide("loading");
            sub.unsubscribe();
          });
        }
        else {
          this.spinner.hide("loading");
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account from the below list before choosing to Request Revision*", "Request Revision", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  toggleEmailNotifications(noticeType){
    if (noticeType == 'enable'){
      if (this.selection.selected.filter(p => p.allowEnable == true).length > 0){     
        let dialogRef = this.dialog.open(ToggleEmailNotificationsDialogComponent, {
          width: '600px',
          data: {
            noticeType: noticeType
          }
        });

        dialogRef.afterClosed().subscribe(notificationPolicies => {      
          if (notificationPolicies){
            this.spinner.show("loading"); 
            var policies = [];

            if (notificationPolicies["applyAllPolicies"] == "true"){
              for(let policy of this.onlineRenewalPolicies){
                var emailNotificationPolicies = {}
                emailNotificationPolicies["renewalId"] = policy["renewalId"];
                emailNotificationPolicies["applyAllPolicies"] = notificationPolicies["applyAllPolicies"];
                emailNotificationPolicies["changeType"] = notificationPolicies["changeType"];
                emailNotificationPolicies["userId"] = notificationPolicies["userId"];

                policies.push(emailNotificationPolicies);
              }
            }
            else {
              for(let policy of this.selection.selected){
                var emailNotificationPolicies = {}
                emailNotificationPolicies["renewalId"] = policy["renewalId"];
                emailNotificationPolicies["applyAllPolicies"] = notificationPolicies["applyAllPolicies"];
                emailNotificationPolicies["changeType"] = notificationPolicies["changeType"];
                emailNotificationPolicies["userId"] = notificationPolicies["userId"];

                policies.push(emailNotificationPolicies);
              }
            }

            var sub = this.onlineRenewals.ToggleEmailNotifications(policies).subscribe( d => {          
              this.selection.clear();
              this.searchForm.reset();
              this.getOnlineRenewals();
              this.spinner.hide("loading");
              sub.unsubscribe();
            });
          }
        });
      }
      else{
        this.toastr.warning("*Please select the account(s) from the below list before choosing to Enable Notices*", "Enable Notices", {
          timeout: 0,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
          ],
          position: SnotifyPosition.centerCenter
        }); 
      }
    }
    else if (noticeType == 'disable'){
      if (this.selection.selected.filter(p => p.allowDisable == true).length > 0){     
        let dialogRef = this.dialog.open(ToggleEmailNotificationsDialogComponent, {
          width: '600px',
          data: {
            noticeType: noticeType
          }
        });

        dialogRef.afterClosed().subscribe(notificationPolicies => {      
          if (notificationPolicies){
            this.spinner.show("loading"); 
            var policies = [];

            if (notificationPolicies["applyAllPolicies"] == "true"){
              for(let policy of this.onlineRenewalPolicies){
                var emailNotificationPolicies = {}
                emailNotificationPolicies["renewalId"] = policy["renewalId"];
                emailNotificationPolicies["applyAllPolicies"] = notificationPolicies["applyAllPolicies"];
                emailNotificationPolicies["changeType"] = notificationPolicies["changeType"];
                emailNotificationPolicies["userId"] = notificationPolicies["userId"];

                policies.push(emailNotificationPolicies);
              }
            }
            else {
              for(let policy of this.selection.selected){
                var emailNotificationPolicies = {}
                emailNotificationPolicies["renewalId"] = policy["renewalId"];
                emailNotificationPolicies["applyAllPolicies"] = notificationPolicies["applyAllPolicies"];
                emailNotificationPolicies["changeType"] = notificationPolicies["changeType"];
                emailNotificationPolicies["userId"] = notificationPolicies["userId"];

                policies.push(emailNotificationPolicies);
              }
            }

            var sub = this.onlineRenewals.ToggleEmailNotifications(policies).subscribe( d => {          
              this.selection.clear();
              this.getOnlineRenewals();
              this.spinner.hide("loading");
              sub.unsubscribe();
            });
          }
        });
      }
      else{
        this.toastr.warning("*Please select the account(s) from the below list before choosing to Disable Notices*", "Disable Notices", {
          timeout: 0,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
          ],
          position: SnotifyPosition.centerCenter
        }); 
      }
    }
  }

  updateEmail(){
    if (this.selection.selected.length == 1){  
      let dialogRef = this.dialog.open(UpdateEmailDialogComponent, {
        width: '700px',
        data: {
          policies: this.selection.selected
        }
      });

      dialogRef.afterClosed().subscribe(updatePolicies => {      
        if (updatePolicies){
          var policies = [];

          if (updatePolicies["applyAllPolicies"] == "true"){
            for(let policy of this.onlineRenewalPolicies){
              var updateEmailPolicies = {}
              updateEmailPolicies["policyId"] = policy["policyId"];
              updateEmailPolicies["renewalId"] = policy["renewalId"];
              updateEmailPolicies["agentName"] = updatePolicies["agentName"];
              updateEmailPolicies["agentEmail"] = updatePolicies["agentEmail"];
              updateEmailPolicies["applyAllPolicies"] = updatePolicies["applyAllPolicies"];
              updateEmailPolicies["changeType"] = "updateAgentInfo";
              updateEmailPolicies["userId"] = updatePolicies["userId"];

              policies.push(updateEmailPolicies);
            }
          }
          else {
            for(let policy of this.selection.selected){
              var updateEmailPolicies = {}
              updateEmailPolicies["policyId"] = policy["policyId"];
              updateEmailPolicies["renewalId"] = policy["renewalId"];
              updateEmailPolicies["agentName"] = updatePolicies["agentName"];
              updateEmailPolicies["agentEmail"] = updatePolicies["agentEmail"];
              updateEmailPolicies["applyAllPolicies"] = updatePolicies["applyAllPolicies"];
              updateEmailPolicies["changeType"] = "updateAgentInfo";
              updateEmailPolicies["userId"] = updatePolicies["userId"];

              policies.push(updateEmailPolicies);
            }
          }

          var sub = this.onlineRenewals.UpdateEmail(policies).subscribe( d => {          
            this.selection.clear();
            this.searchForm.reset();
            this.getOnlineRenewals();
            sub.unsubscribe();
          });
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account from the below list before choosing to Update Your Email*", "Update Email", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }

  deleteRenewal(){
    if (this.selection.selected.filter(p => p.allowDelete == true).length > 0){   
      let dialogRef = this.dialog.open(DeleteRenewalDialogComponent, {
        width: '800px',
        data: {
          policies: this.selection.selected.filter(p => p.allowDelete == true)
        }
      });
      
      dialogRef.afterClosed().subscribe(deletedPolicy => {        
        if (deletedPolicy){
          deletedPolicy["changeType"] = "deletePolicy";
          var sub = this.onlineRenewals.saveOnlineRenewal(deletedPolicy).subscribe( d => {            
            this.selection.clear();
            this.searchForm.reset();
            this.getOnlineRenewals();
            sub.unsubscribe();
          });
        }
      });
    }
    else{
      this.toastr.warning("*Please select the account(s) from the below list before choosing to Delete Quotes*", "Delete Quotes", {
        timeout: 0,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          { text: 'Ok', action: (toast) => { this.toastr.remove(toast.id); }, bold: true }
        ],
        position: SnotifyPosition.centerCenter
      }); 
    }
  }
}
