import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SecurityService {
  apiUrl;

  constructor(
    @Inject("environment") private environment,
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/security`;
  }

  public getSecuritySettings() {
    return this.http.get(`${this.apiUrl}/GetSecuritySettings`);
  }

  public getUserRights() {
    return this.http.post(`${this.apiUrl}/GetUserRights`, {});
  }

  public saveGroup(json) {
    return this.http.post(`${this.apiUrl}/SaveGroup`, json);
  }

  public getAccessibleFileTypesList() {
    return this.http.post(`${this.apiUrl}/GetAccessibleFileTypesList`, {});
  }

  public getPowerBIEmbedToken(groupId, reportId) {
    return this.http.get(
      `${this.apiUrl}/GetPowerBIEmbedToken?groupId=${groupId}&reportId=${reportId}`
    );
  }
}
