import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  public Base64ToBlob(base64, contentType) {
    let sliceSize = 512;
    let byteCharacters = atob(base64);
    let byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public CreateBlob(d: any, mimeType) {
    let reader = new FileReader();
    
    var binary = atob((<string>d).replace(/\s/g, ""));
    var len = binary.length;

    // create ArrayBuffer with binary length
    var buffer = new ArrayBuffer(len);

    // create 8-bit Array
    var view = new Uint8Array(buffer);

    // save unicode of binary data into 8-bit Array
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    var blob = new Blob([view], { type: mimeType });
    return blob;
  }
}
